<script setup>
import AppLayout from '@/Layouts/AppLayout.vue'
import {useMainStateStore} from '@/stores/mainState.js';
import {saveRemoteAnswers} from '@/libraries/network.js';
import {browserDt} from '@/libraries/utils.js';
import GraphEnd from '@/Pages/sharedComponents/images/GraphEnd.vue';
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import CircledStar from '@/Pages/sharedComponents/icons/CircledStar.vue';

const mainStore = useMainStateStore()
const { user } = storeToRefs(mainStore)

const isAnswered = ref(false)
const savedAnsweredPrize = ref("")

const getMaxScore = () => {
    if(mainStore.user.is_treatment){
        return 72 // actually: 118, but the game gives from 0 to 60 points (02/02/2024)
    } else {
        return 56 // actually: 111, but the game gives from 0 to 60 points (02/02/2024)
    }
}

const getLimit0 = () => Math.round(0.2*getMaxScore()) // 20%
const getLimit1 = () => Math.round(0.8*getMaxScore()) // 80%
// const limit2 = 60

const prizeNumber = () => {
	const score = mainStore.user.score
	if(score < getLimit0()) {
		return 1
	}
	if(score <= getLimit1()) {
		return 2
	}
    return 3
}

const capScore = () => {
    if(mainStore.user.score > getMaxScore()){
        return getMaxScore()
    } else {
        return mainStore.user.score
    }
}

const save = async () => {
	const num = prizeNumber()
	try {
		await saveRemoteAnswers([{
			total_question_id: 'end',
			module_id        : 9999,
			page_id          : 1,
			question_id      : 1,
			question_type    : 'end',
			value            : num,
			value_text       : null,
			browser_dt       : browserDt(),
		}])
		isAnswered.value = true
		savedAnsweredPrize.value = num
	} catch(e) {
		console.error('end.save catch', e);
	}
}

const euroVal = computed(() => {
    const num = prizeNumber()
    let val = 0
    switch(num){
        case 1:
            val = 5
            break
        case 2:
            val = 15
            break
        case 3:
            val = 25
            break
    }
    return (val).toFixed(2)
})

onMounted(()=>{
    isAnswered.value = ('end' in user.value.questions)
    if(isAnswered.value){
        savedAnsweredPrize.value = user.value.questions['end'].value // 1, 2, 3
    } else {
        save()
    }
})
</script>

<template>
	<AppLayout>
		<div class="min-h-screen py-16 px-6 text-white bg-blue">
			<div class="text-lightcoral font-bold text-3xl text-center">
				Congratulazioni,<br>hai completato il percorso
			</div>
			<div class="pt-6 px-4 text-xl">
				Il bilancio finale dei punti che hai accumulato sulla piattaforma è <span class="font-bold text-lightcoral"><CircledStar class="pb-[0.2rem] w-6 h-6 inline-block" :color="'lightcoral'" />{{ mainStore.user.score }}</span>.
                Questi punti ti garantiscono l'accesso ad un voucher del valore specificato a fianco del grafico, che potrai convertire in buoni spesa o acquisto.
			</div>
			<div class="px-4 pt-8">
				Che valore ha il tuo voucher?
			</div>
			<div class="mt-10 flex flex-row justify-evenly">
				<GraphEnd
					:limit0="getLimit0()"
					:limit1="getLimit1()"
					:maxScore="getMaxScore()"
					:score="capScore()"
					style="width:18rem;height:auto;"
				/>
                <div v-if="prizeNumber()" class="text-left m-auto ml-[-4rem] w-1/3 vertical-center text-white text-lg">
                    Hai vinto un voucher dal valore di <br><span class="text-2xl font-bold text-yellow">{{ euroVal }} Euro!</span>
                </div>
			</div>

			<div class="pt-8">
				<div v-if="prizeNumber()">
                    <div class="text-2xl text-yellow">
						Ottimo lavoro! Riceverai il tuo premio via email a fine progetto.
					    <div class="mt-4 text-2xl font-bold text-white">Grazie per aver preso parte alla ricerca!</div>
					</div>
                </div>
				<div v-else class="mt-6 text-xl text-coral px-6">
					Sfortunatamente non hai ottenuto abbastanza punti per riscattare un premio.
					<div class="mt-4 text-2xl font-bold text-white">Grazie per aver preso parte alla ricerca!</div>
				</div>
			</div>
		</div>
	</AppLayout>
</template>
