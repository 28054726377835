import { useMainStateStore } from "@/stores/mainState";
import { ContentObject } from "@/dataObjects/ContentObject";
import {getCurrentInstance, onBeforeMount, onBeforeUnmount, onBeforeUpdate, onMounted, onUpdated} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {defineProps, onActivated, onDeactivated, onErrorCaptured, onRenderTracked, onRenderTriggered, onServerPrefetch, onUnmounted} from '@vue/runtime-core';
import {onBeforeRouteLeave, onBeforeRouteUpdate} from 'vue-router';
import {inertiaVisitOptions} from '@/libraries/network.js';

/**
 * default component css class (homeModuleCard >> $-home-module-card)
 *
 * @param instance {?ComponentInternalInstance}
 * @param classes {Object}
 * @returns {Object}
 */
export function compClass(instance = null, classes = {}) {
	const className = '$-' + (
		instance || getCurrentInstance()
	).type.__name.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()

	classes[className] = true
	return classes
}


export const logout = async function () {
    window.location.href = '/logout';
}

export const browserDt = () => Math.round(Date.now() / 1000);

export const hashString = function (stringToBeHashed, seed = 0) {
    // cyrb53 - https://stackoverflow.com/a/52171480/11599600
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < stringToBeHashed.length; i++) {
        ch = stringToBeHashed.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export const setCookie = function (cookieName, cookieValue, cookieExpirationHours = 24) {
    const d = new Date();
    d.setTime(d.getTime() + (cookieExpirationHours * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const getCookie = function (cookieName) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const removeCookie = function (cookieName) {
    if (getCookie(cookieName)) {
        document.cookie = cookieName+'=; Max-Age=-99999999;';
    }
}

export const getBrowserSessionId = () => {
    let mainStateStore = useMainStateStore()
    console.debug("[utils/getBrowserSessionId] mainStateStore: ", mainStateStore)
    let browserSessionId = mainStateStore.browserSessionId
    console.debug("[utils/getBrowserSessionId] browserSessionId: ", browserSessionId)
    return browserSessionId
}

export const achievementCodeDecoder = (achievementCode) => {
    let achievementStep = String(achievementCode).split('_').pop()
    let achievementPrefix = String(achievementCode).replace('_'+achievementStep, '')
    return { achievementPrefix, achievementStep }
}

export const achievementCodeEncoder = (achievementPrefix, achievementStep) => {
    let achievementCode = String(achievementPrefix)+"_"+String(achievementStep)
    return achievementCode
}

export const contentConfigObjectLoader = async (contentPath, expected_type=null) => {
    const basePath = "/platformConfig/content"
    const fileName = "contentConfig.json"
    let fetchResponse = await fetch(basePath+'/'+contentPath+'/'+fileName)
    let contentConfigObject = await fetchResponse.json()
    let candidateContentObject = new ContentObject(contentConfigObject)
    if(expected_type) {
        if(candidateContentObject.type != expected_type){
            console.error(candidateContentObject)
            throw new Error("the candidate ContentObject is not of type `"+expected_type+"`!")
        } else {
            return candidateContentObject
        }
    } else {
        return candidateContentObject
    }
}

export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//deep search: returns first object with entry key:val
// 1 !== '1'
//if param 'multiple' is setted, returns array of objs
export const findByKeyVal = function(object, key, value, multiple) {
	let ret = null;
	if(multiple) {
		ret = [];
	}
	//https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-object

	//attention: it could be called from an Array too
	if(object instanceof Array) {
		for(let obj, i = 0; i < object.length; i++) {
			//undefined is an object -.-"
			if(typeof object[i] === 'object' && object[i]) {
				//copy args
				const newArgs = [...arguments]
				//replace object arg with its (array) element
				newArgs[0] = object[i]
				//recurse with local params
				obj = findByKeyVal(...newArgs)
				if(obj) {
					if(!multiple) {
						return obj;
					}
					else {
						ret = ret.concat(obj);
					}
				}
			}
		}
	}
	else {
		if(object[key] === value) {
			if(!multiple) {
				return object;
			}
			else {
				ret.push(object);
			}
		}

		for(let _key in object) {
			if(!object.hasOwnProperty(_key)) {
				continue
			}
			if(object[_key] === null) {
				continue;
			}

			//recurse
			if(typeof object[_key] === 'object') {
				//copy args
				const newArgs = [...arguments]
				//replace object arg with its element
				newArgs[0] = object[_key]
				//recurse with local params
				const obj = findByKeyVal(...newArgs)
				if(obj) {
					if(!multiple) {
						return obj;
					}
					else {
						ret = ret.concat(obj);
					}
				}
			}
		}
	}

	return ret;
}

/**
 * PAGE.PROPS E NAVIGAZIONE CON BACK
 * Al back l'oggetto page e il suo props ha valori diversi in base a come e dove lo si recupera.
 *
 * COME SI USA 1 (layout.vue e page.vue)
 * Creata variabile in setup: const inertiaPageProps = usePage().props
 * Utilizzo diretto: inertiaPageProps.user
 *
 * COME SI USA 2 (layout.vue e page.vue)
 * Soltanto utilizzo con chiamata: usePage().props.user
 *
 * COME SI USA 3 (page.vue)
 * Creata variabile in setup con defineProps: const props = defineProps({	user: Object })
 *
 * DOVE SI USA
 * Nel setup e dentro gli handler degli eventi: onBeforeRouteLeave, onBeforeRouteUpdate, onBeforeMount,
 * onMounted, onBeforeUpdate, onUpdated, onBeforeUnmount, onUnmounted,
 *
 * L'opzione preserveState di Inertia.visit aumenta le combinazioni possibili. Con true aumentano e
 * cambiano gli eventi (es. mounted al posto di updated), comunque in generale è meglio impostare
 * preserveState a false in modo da aumentare le combinazioni in cui il risultato di page.props
 * è definito e aggiornato
 * @see resources/js/libraries/network.js
 */
/**
 * @param name {string} 'AppLayout' or 'Home'
 * @param isPage {boolean}
 */
export const dumpSetup = (name, isPage= false) => {
	const inertiaPageProps = isPage
		? defineProps({	user: Object 	})
		: usePage()?.props

	console.log('preserveState', inertiaVisitOptions.preserveState)

	//setup
	//in page, dopo aver salvato dati, al back usePage().props va in errore come se usePage restituisse undefined,
	//eppure da debug + console restituisce anche un oggetto props con 'usePage()' e Object.keys(usePage())
	//
	//in layout invece setup1 e setup2 sono materialmente la stessa cosa
	console.log(`${name}.setup`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	//handlers
	onBeforeMount(() => {
		console.log(`${name}.onBeforeMount`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onMounted(() => {
		console.log(`${name}.onMounted`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onBeforeUpdate(() => {
		console.log(`${name}.onBeforeUpdate`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onUpdated(() => {
		console.log(`${name}.onUpdated`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onBeforeUnmount(() => {
		console.log(`${name}.onBeforeUnmount`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onUnmounted(() => {
		console.log(`${name}.onUnmounted`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	//
	onActivated(() => {
		console.log(`${name}.onActivated`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onDeactivated(() => {
		console.log(`${name}.onDeactivated`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onServerPrefetch(() => {
		console.log(`${name}.onServerPrefetch`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	// troppo frequente
	// onRenderTracked(() => {
	// 	console.log(`${name}.onRenderTracked`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	// })
	onRenderTriggered(() => {
		console.log(`${name}.onRenderTriggered`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onErrorCaptured(() => {
		console.log(`${name}.onErrorCaptured`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onBeforeRouteUpdate(() => {
		console.log(`${name}.onBeforeRouteUpdate`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
	onBeforeRouteLeave(() => {
		console.log(`${name}.onBeforeRouteLeave`, usePage().props?.user?.achievements.length, inertiaPageProps?.user?.achievements.length)
	})
}
