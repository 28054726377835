import {reactive, toRaw} from 'vue';
import { QuestionObject } from './QuestionObject';

export class UserObject {
	constructor() {
		this.name = null
		this.is_treatment = null
		this.achievements = reactive([])
		//serve solo a idratare le domande, uso un'oggetto per accedere alla singola senza ricerca
		this.questions = {}
		this.family_code = null
		this.family_data = {}
		this.is_youth_or_adult = null
	}

	hydrate(inertiaPagePropsUser) {
		if(!this.name) {
			this.name = inertiaPagePropsUser.name
			this.is_treatment = inertiaPagePropsUser.is_treatment
		}
		this.score = inertiaPagePropsUser.score

		this.family_code = inertiaPagePropsUser.family_code
		this.family_data = inertiaPagePropsUser.family_data
		this.is_youth_or_adult = inertiaPagePropsUser.is_youth_or_adult

		inertiaPagePropsUser.achievements.forEach(newAch => {
			//push if not exists
			if(!this.achievements.find(oldAch => oldAch.id === newAch.id)) {
				this.achievements.push(newAch)
			}
		})

		/**
		 * riempio i question.answerValue con il valore del db
		 */
		inertiaPagePropsUser.answers.forEach(answer => {
            if(toRaw(answer).total_question_id=="end"){
                // martellata allucinante
                this.questions["end"] = answer
            } else {
                this.questions[answer.total_question_id]?.hydrate(answer)
            }
		})
	}
}
