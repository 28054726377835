import {browserDt, getBrowserSessionId} from "./utils"
import {router, useForm, usePage} from "@inertiajs/vue3"
import { useNavigationStore } from "@/stores/navigation"
import {pushState, replaceState} from '@/libraries/navUtils.js';

export const DEFAULT_PATH = ""

const ANSWERS_INERTIA_ROUTE = "/answers"

const BROWSEREVENTS_INERTIA_ROUTE = "/browserEvent"
//todo per i video SI Usa una risposta con valore ANSWER_VALUE_VIDEO_COMPLETED
const START_EVENT_NAME = "start"
const END_EVENT_NAME = "end"

const refreshCurrentPath = () => {
	let currentPath = window.location.pathname

	if (currentPath === ANSWERS_INERTIA_ROUTE) {
		currentPath = useNavigationStore().getCurrentFullPath() ?? DEFAULT_PATH
	}
	history.replaceState(history.state, document.title, currentPath)
}
export const saveRemoteAnswers = async (answers = []) => {
	console.debug("[saveRemoteAnswers]", answers)
	if(!answers.length) {
		return
	}

	await new Promise((resolve, reject) => {
		router.visit(ANSWERS_INERTIA_ROUTE, {
			...inertiaVisitOptions,
			data: {
				answers: answers,
				browser_dt: browserDt(),
			},
			onSuccess: page => {
				resolve()
			},
			onError: errors => {
				reject(errors)
			},
		})
	})
}

// ---------------------------------------------------------------------------
// BROWSER EVENTS

export const _createBrowserEvent = async (eventType, eventValue, browserSessionId) => {
	console.debug("[_createBrowserEvent] - ", eventType, eventValue, browserSessionId)
	await new Promise((resolve, reject) => {
		router.visit(BROWSEREVENTS_INERTIA_ROUTE, {
			...inertiaVisitOptions,
			data: {
				type: eventType,
				browser_session_id: browserSessionId,
				value: eventValue,
				browser_dt: browserDt()
			},
			onCancel: () => {
				reject("Visit canceled! Are you sure you awaited the call?")
			},
			onSuccess: page => {
				resolve()
			},
			onError: errors => {
				reject(errors)
			},
		})
	})
}

export const sendStartBrowserEvent = async (browserSessionId) => {
	await _createBrowserEvent(START_EVENT_NAME, "", browserSessionId)
}

export const sendEndBrowserEvent = async (browserSessionId) => {
	await _createBrowserEvent(END_EVENT_NAME, "", browserSessionId)
}

/**
 * Inertia.visit, fatto per navigare, al salvataggio dati con le post aggiunge una fastidiosa voce
 * di cronologia del browser.
 * IN bcb ho usato per queste cose useForm.put, che però qui mi dà lo stesso problema di Inertia.visit.
 * In bcb ho visto che useForm.put fa 2 replace dell'url con il valore attuale.
 * Pootrei usare Axios, ma non avrei la vera gestione di Inertia a cui stiamo puntando per usare
 * questo strumento con cognizione.
 *
 * Soluzione: in onBefore salvo l'url attuale e nella onFinish lo ripristino con una replaceState
 */
export const inertiaVisitOptions = {
	method: 'post',
	data: {},
	replace: false,
	preserveState: true,
	preserveScroll: true,
	//ATTENZIONE only fa scattare onError solo se ha dentro 'errors' oppure se è vuoto
	only: [],
	headers: {},
	errorBag: null,
	forceFormData: false,
	onCancelToken: cancelToken => { },
	onCancel: () => { },
	onBefore: visit => {
		visit.url.myOrigin = location.href
		//console.log('onBefore',visit)
	},
	onStart: visit => { },
	onProgress: progress => { },
	onSuccess: page => { },
	onError: errors => { },
	onFinish: visit => {
		replaceState(visit.url.myOrigin)
	},
}