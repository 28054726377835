import { defineStore } from 'pinia'
import {browserDt, hashString} from '@/libraries/utils'
import { UserObject } from '@/dataObjects/UserObject'
import { sendStartBrowserEvent, sendEndBrowserEvent, } from '@/libraries/network'
import {ModuleObject} from '@/dataObjects/ModuleObject.js';
import {unref} from 'vue';
import {useRoute} from 'vue-router';

export const useMainStateStore = defineStore('mainState', {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			browserSessionId: '',
			lang: '',
			user: new UserObject(),
			modules: [],
			/*permette di togliere z-10 all'header e risolvere i soliti problemi di z-index*/
			isVideoLightboxVisible: null,
			// ---
			// ! SIGNALS BOOLEAN TOGGLES
			// ! TODO think about an event bus!
			isAppInitializedSignal: false,
			isAchievementTutorialAskedSignal: false,
			environment: (() => {
				if(location.hostname === '127.0.0.1' || location.hostname === 'localhost') {
					return 'local'
				}
				if(location.hostname.indexOf('dev.') === 0) {
					return 'dev'
				}
				if(location.hostname.indexOf('staging.') === 0) {
					return 'staging'
				}

				return 'production'
			})()
		}
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	getters: {
		getSession(state) {
			return state.browserSessionId
		}
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 *
		 * @param data {Array} per i moduli da data.js
		 * @param inertiaPageProps {PageProps} per le risposte del db
		 */
		hydrate({data: data, inertiaPageProps}) {

			//push modules into state, once only
			if(this.modules.length) {
				return
			}

			data.forEach((module, index) => {
				//user not treatment > remove 'treatment only' pages
				if(!inertiaPageProps.user.is_treatment) {
					module.pages = module.pages.filter(page => !page.treatmentOnly)
				}

				//create objects
				const newModule = new ModuleObject(module, {
					index: index,
					isFirst: index === 0,
					isLast: index === data.length - 1,
				})
				this.modules.push(newModule)

				//push all questions into user.questions
				newModule.pages.forEach(page => {
					page.questions.forEach(question => {
						this.user.questions[question.id] = question
					})
				})
			})

			this.user.hydrate(inertiaPageProps.user)

			window.__state__ = unref(this)
		},
		/**
		 * @returns {?ModuleObject}
		 */
		currentModule() {
			const currentRoute = useRoute()
			//module0_page1 >> module0
			const moduleId = currentRoute.name.replace(/_.+$/, '')

			return this.modules.find(module => module.id === moduleId)
		},
		async startSession() {
			if (this.browserSessionId == '') {
				let currentDt = browserDt()
				let userEmail = this.user.email
				this.browserSessionId = hashString(
					String(currentDt) + String(userEmail)
				)
				await sendStartBrowserEvent(this.browserSessionId)
			}
		},
		async resumeSession(browserSessionId) {
			this.browserSessionId = browserSessionId
			await sendStartBrowserEvent(browserSessionId)
		},
		async endSession() {
			if (this.browserSessionId != '') {
				await sendEndBrowserEvent(this.browserSessionId)
				this.browserSessionId == ''
			}
		},
		// ! SIGNALS "EMIT" ACTIONS
		// ! TODO think about an event bus!
		//Toggle App Initialized Signal
		appInitializedSignal(){
			this.isAppInitializedSignal = !this.isAppInitializedSignal
			return this.isAppInitializedSignal
		}
	},
	persist: true
})
