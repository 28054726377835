<script setup>
import moment from 'moment/moment';
import {computed, getCurrentInstance, onUpdated} from 'vue';
import {compClass} from '@/libraries/utils.js';
import { useNavigationStore } from '@/stores/navigation'
import {useMainStateStore} from '@/stores/mainState.js';
import {ModuleObject} from '@/dataObjects/ModuleObject.js';
import ArrowRight from '@/Pages/sharedComponents/icons/ArrowRight.vue';
import Lock from '@/Pages/sharedComponents/icons/Lock.vue';
import ProgressCircle from '@/Pages/sharedComponents/icons/ProgressCircle.vue';
import ModulesLines from '@/Pages/sharedComponents/images/ModulesLines.vue';
const navigationStore = useNavigationStore()
// non sono riuscito a importare come variabili la conf di tw
//https://tailwindcss.com/docs/configuration#referencing-in-java-script

const props = defineProps({
	module: ModuleObject,
})
const instance = getCurrentInstance()
const mainStore = useMainStateStore()
//
const progressCircleColor = () => {
	if(props.module.state === ModuleObject.STATE_UNACCESSIBLE) {
		return 'blue'
	}
	if(props.module.state === ModuleObject.STATE_ACCESSIBLE_UNEDITABLE) {
		return 'lightblue'
	}
	return 'coral'
}
const classes1 = () => ({
	'pt-1': props.module.state !== ModuleObject.STATE_UNACCESSIBLE,
	'pt-2': props.module.state === ModuleObject.STATE_UNACCESSIBLE,
})
const classes2 = () => ({
	'py-3': props.module.state !== ModuleObject.STATE_UNACCESSIBLE,
})

const calcClip = () => {
	if(props.module.isLast){
		return "clip-path: inset(0);"
	} else if(!props.module.isLast && props.module.state==ModuleObject.STATE_UNACCESSIBLE){
		return "clip-path: inset(-100% 0 -100% 0);"
	} else {
		return "clip-path: inset(-10% 0 -10% 0);"
	}
}
</script>

<template>
	<div :class="compClass(instance)" class="flex basis-full" :style="calcClip()">
		<div class="basis-auto px-5" :class="classes1()">
			<div class="" :class="classes2()">
				<ProgressCircle
					:perc="module.completionPerc()"
					:color=progressCircleColor()
					class="w-10 h-10"
				/>
			</div>

			<div v-if="!module.isLast && module.state==ModuleObject.STATE_UNACCESSIBLE" class="flex justify-center relative h-full w-full">
				<ModulesLines
					class="absolute left-0 right-0 m-auto"
					:style="'width:0.9rem;'"
				/>
			</div>
			<div v-else-if="!(module.isLast && (module.state==ModuleObject.STATE_UNACCESSIBLE))" class="flex justify-center relative h-full w-full">
				<ModulesLines
					class="absolute left-0 right-0 m-auto"
					:style="'width:0.9rem'"
				/>
			</div>
		</div>
		<div class="basis-full">
			<div v-if="module.state !== ModuleObject.STATE_UNACCESSIBLE">
				<div class="bg-blue text-white rounded-3xl clickable"
					@click="() => navigationStore.goToPage(module.intro.id)">
					<div class="pt-5 px-5 flex justify-between">
						<div class="text-xl open-sans">
							Missione {{module.index}}
						</div>
						<div >
							<ArrowRight class="h-6 w-6" :color="'yellow'" />
						</div>
					</div>
					<div class="mt-6 mb-2 px-2 text-[1.35rem] font-semibold text-center">
						{{ module.title }}
					</div>
					<div class="h-60"
						style="background-position: center bottom;background-size: 100% auto;"
						:style="`background-image:url('/img/module${module.index}/bgListItem.svg')`"
					/>
				</div>
			</div>
			<div v-else
				class="px-7 flex justify-between items-center unclickable bg-lightcoral text-white rounded-3xl">
				<div class="py-4 text-xl font-semibold open-sans">
					{{moment(module.date0).format('DD MMMM YYYY')}}
				</div>
				<div>
					<Lock :color="'white'" class="w-7" />
				</div>
			</div>
		</div>
	</div>
</template>
