<script setup>
import HomeModuleItem from '@/Pages/sharedComponents/homeModuleListItem.vue';
import {compClass} from '@/libraries/utils.js'
import {getCurrentInstance, onMounted} from 'vue';
import {useMainStateStore} from '@/stores/mainState.js';
import CustomScrollbar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css'
import $ from 'jquery'

const instance = getCurrentInstance()

const mainStore = useMainStateStore()

onMounted(()=>{
    const safetyOffset = 50
    $('.scrollbar__scroller').height(window.innerHeight - safetyOffset)
})

</script>

<template>
    <CustomScrollbar :thumb-max-size="500">
        <div id="home-container" class="pt-4 px-4 h-full flex flex-col">
            <div class="pr-9" :class="compClass(instance)">
                <div class="basis-full">
                    <div v-for="(module, mIndex) in mainStore.modules" :key="mIndex"
                        class="mb-8">

                        <HomeModuleItem :module="module" />
                    </div>
                </div>
            </div>
        </div>
    </CustomScrollbar>
</template>
