<script setup>
import Menu from '@/Pages/sharedComponents/menu.vue';
import $ from 'jquery'
const page = usePage()
import {usePage} from '@inertiajs/vue3';
import {useMainStateStore} from '@/stores/mainState.js';
import {data} from '@/config/data.js';
import {onMounted, ref} from 'vue';
import {useNavigationStore} from '@/stores/navigation.js';
import moment from 'moment/moment';
import {dumpSetup} from '@/libraries/utils.js';

const inertiaPageProps = usePage().props

const mainStore = useMainStateStore()
const navigationStore = useNavigationStore()
/**
 * nella navigazione da cronologia inertiaPageProps puo essere null
 */
if(inertiaPageProps?.user) {
	mainStore.hydrate({
		data: data,
		inertiaPageProps: inertiaPageProps,
	})
}

// dumpSetup('AppLayout', false)

const header = ref(null)
const headerFake = ref(null)
const main = ref(null)

onMounted(() => {
	const headerFakeEl = $(headerFake.value);
	const headerEl = $(header.value);
	const mainEl = $(main.value);

	headerFakeEl.css('padding-bottom', headerEl.height() + 'px');
	mainEl.height(document.body.clientHeight - headerEl.height());

	//console.debug("outer, inner, html client, body client", window.outerHeight, window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight)
	// window.addEventListener('resize', () => {
	//     headerFakeEl.css('padding-bottom', headerEl.height() + 'px');
	//     console.debug("outer, inner, html client, body client", window.outerHeight, window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight)
	//     mainEl.height(document.body.clientHeight - headerEl.height());
	// });
})

const headerClasses = () => ({
	'z-10': !mainStore.isVideoLightboxVisible
})

//debug with devtools
if(mainStore.environment !== 'production') {
	window.moment = moment
	window.__data__ = data
	window.__mainStore__ = mainStore
	window.__navigationStore__ = navigationStore
}

//la testata e il menu sono fuori dal wrapper per essere estendibili a 100vw in desktop
</script>
<template>
	<div class="flex flex-col h-screen text-2xl font-medium text-blue bg-gradient-to-b from-[#fffdf0] to-[#eaeded]">
		<header v-if="$page.component !== 'login'/*logged?*/"
			ref="header"
			class="fixed w-full bg-ivory" :class="headerClasses()">
			<Menu/>
		</header>

		<div ref="headerFake" class="header-fake basis-auto"/>

		<main class="wrapper basis-full" ref="main">
			<div class="bounds h-full">
				<slot />
			</div>
		</main>
	</div>
</template>
