import { createRouter, createWebHistory } from "vue-router";
import {data} from '@/config/data.js'
import homeModules from '@/Pages/sharedComponents/homeModules.vue'
import about from '@/Pages/about.vue';
import insights from '@/Pages/insights.vue';
import end from '@/Pages/end.vue';

const basePath = '/modules'

//init with home routes
const routes = [
	{
		path: '/',
		component: homeModules,
		name: "homeModules"
	},
	//non raggiungibili con il router
	{
		path: '/insights',
		component: insights,
		name: "insights"
	},
	{
		path: '/about',
		component: about,
		name: "about"
	},
	{
		path: '/end',
		component: end,
		name: "end"
	},
]

//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
data.forEach((module, mIndex) => {
	const mProps = {
		mIndex: mIndex,
	}

	//push intro/end routes
	routes.push({
		path: `${basePath}/module${mIndex}/intro`,
		component: () => import(`@/Pages/modules/module${mIndex}/intro.vue`),
		name: `module${mIndex}_intro`,
		props: mProps
	})
	routes.push({
		path: `${basePath}/module${mIndex}/end`,
		component: () => import(`@/Pages/modules/module${mIndex}/end.vue`),
		name: `module${mIndex}_end`,
		props: mProps
	})

	//push page routes
	module.pages.forEach((page, pIndex) => {
		//non si puo fare perche:
		//Are you trying to use a store before calling "app.use(pinia)"?
		//if(page.treatmentOnly && !mainStore.user.is_treatment) {return}

		pIndex++
		const pProps = {
			...mProps,
			pIndex: pIndex
		}

		routes.push({
			path: `${basePath}/module${mIndex}/page${pIndex}`,
			component: () => import(`@/Pages/modules/module${mIndex}/page${pIndex}.vue`),
			name: `module${mIndex}_page${pIndex}`,
			props: pProps
		})
	})
})

//https://stackoverflow.com/questions/48111573/ multiple router
export const router = createRouter({
	history: createWebHistory(),
	routes: routes
})
