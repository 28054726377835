import {QuestionObject} from '@/dataObjects/QuestionObject.js';


const ALWAYS_UNLOCKED_DATE0 = '2022-01-01'
const ALWAYS_UNLOCKED_DATE1 = '2025-12-31'


/**
 * data starts with 0, pages starts with 1
 */
export const data = [
	{
		title: 'Mettersi comodi',
		date0: '2024-02-05',
		date1: '2024-02-11',
		pages: [
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_VIDEO,
						videoContent: {
							description: "Spiegazione delle varie sezioni della piattaforma e del percorso settimanale.",
							url: "https://vz-2baff7c4-a66.b-cdn.net/75fe0a95-ab71-45f9-a94e-b65fd640fea0/original",
							thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/75fe0a95-ab71-45f9-a94e-b65fd640fea0/thumbnail.jpg"
						}
					},
				],
			},
			{
				title: '',
				questions: [],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_GAME_SLIDER,
					},
				],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_GAME_CHOICE,
					},
				],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_GAME_CODE,
					},
				],
			},
			{
				title: 'Vorremmo sapere quanto la tua opinione venga presa in considerazione quando in famiglia si prendono decisioni su:',
				questions: [
					{
						text: 'Quale modello di elettrodomestico acquistare',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Se compiere piccoli investimenti in efficienza energetica, ad esempio sostituire le lampadine con lampadine efficienti a LED',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
		],
		intro: {
			title: 'La piattaforma, i punti e la tua famiglia',
		},
		end: {
		},
	},
	{
		title: 'Cara energia...',
		date0: '2024-02-12',
		date1: '2024-02-18',
		pages: [
			{
				title: 'Quanto ti ritrovi nelle seguenti affermazioni?',
				questions: [
					{
						text: 'Credo che il cambiamento climatico sia principalmente causato dall’attività umana',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Sono preoccupato/a per il cambiamento climatico',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Agire nel rispetto dell’ambiente è una cosa importante nella mia vita',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Agire nel rispetto dell’ambiente è una cosa importante nella vita del mio familiare che partecipa allo studio',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
			{
				title: 'Rispondi alle domande:',
				questions: [
					{
						text: 'Quanta energia pensi che la tua famiglia abbia consumato nel mese di Novembre 2023? <span class="text-lightcoral">(in kWh)</span>',
						type: QuestionObject.TYPE_SLIDER,
						min: 1,
						max: 400,
					},
					{
						text: 'Hai un contratto ad energia verde, ovvero un contratto in cui l’energia è prodotta da fonti rinnovabili o in cui le emissioni prodotte dai consumi energetici sono compensate?',
						type: QuestionObject.TYPE_BUTTONS,
						buttons: {
							1: 'sì',
							0: 'no',
							2: 'non so',
						}
					},
				],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_VIDEO,
						videoContent: {
							description: 'Che cos’è la carbon footprint?',
							url: "https://vz-2baff7c4-a66.b-cdn.net/62c0cdab-185a-43bb-8bea-620639417f2d/original",
							thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/62c0cdab-185a-43bb-8bea-620639417f2d/thumbnail.jpg"
						}
					},
				],
			},
			{
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'Quale pensi che sia stata la carbon footprint dei consumi elettrici della tua famiglia nel mese di Novembre 2023? <span class="text-lightcoral">(Kg di CO2)</span>',
						type: QuestionObject.TYPE_SLIDER,
						min: 1,
						max: 200,
					},
				],
			},
			// {
			// 	title: '',
			// 	questions: [
			// 		{
			// 			type: QuestionObject.TYPE_VIDEO,
			// 			videoContent: {
			// 				description: 'Quanto impatta il consumo di energia sull’ambiente?',
			// 				url: "https://vz-2baff7c4-a66.b-cdn.net/398c3116-bf13-4c76-92a7-f8662fdb8d01/original",
			// 				thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/398c3116-bf13-4c76-92a7-f8662fdb8d01/thumbnail.jpg"
			// 			}
			// 		},
			// 	],
			// 	treatmentOnly: true,
			// },
			{
				title: '',
				questions: [],
				treatmentOnly: true,
			},
			{
				title: '',
				questions: [],
				treatmentOnly: true,
			},
		],
		intro: {
			title: 'La carbon footprint dei consumi elettrici della tua famiglia',
		},
		end: {
		},
	},
	{
		title: 'Ritratto di famiglia',
		date0: '2024-02-19',
		date1: '2024-02-25',
		pages: [
			{
				title: 'Quante persone ci sono in casa tua?',
				questions: [
					{
						text1: 'Adulti',
						text2: 'Età > 23 anni',
						type: QuestionObject.TYPE_INCREASE_DECREASE,
					},
					{
						text1: 'Giovani',
						text2: 'Età < 23 anni',
						type: QuestionObject.TYPE_INCREASE_DECREASE,
					},
				],
			},
			{
				title: 'Indica in quali giorni e fasce orarie sei stato prevalentemente a casa la settimana scorsa',
				questions: [
					{
						text: 'Lunedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Martedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Mercoledì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Giovedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Venerdì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Sabato',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Domenica',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
				],
			},
			{
				title: 'Indica in quali giorni e fasce orarie l’altro partecipante è stato prevalentemente a casa la settimana scorsa',
				questions: [
					{
						text: 'Lunedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Martedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Mercoledì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Giovedì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Venerdì',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Sabato',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
					{
						text: 'Domenica',
						type: QuestionObject.TYPE_DAYPART_BUTTONS,
					},
				],
			},
			{
				title: 'quali elettrodomestici e dispositivi elettronici sono stati usati ieri in casa tua?',
				questions: [
					{
						type: QuestionObject.TYPE_DRAG_AND_DROP,
					},
				],
			},
			// {
			// 	title: 'Quali elettrodomestici e dispositivi elettronici l’altro partecipante usa quando è a casa?',
			// 	questions: [
			// 		{
			// 			type: QuestionObject.TYPE_DRAG_AND_DROP,
			// 		},
			// 	],
			// },
			{
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'Quanto pensi che la tua famiglia abbia consumato a Novembre 2023 rispetto ad altri clienti di Hera simili?',
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						buttons: {
							1: 'Tanto (nel 50% più alto)',
							2: 'Sotto la media (tra la media e il 20% più efficiente)',
							3: 'Poco (nel 20% più efficiente)',
						},
					},
				],
			},
			{
				title: '',
				questions: [],
			},
			{
				title: '',
				questions: [],
			},
			{
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'A quale dei profili di consumo che ti abbiamo mostrato pensi che appartenga la tua famiglia?',
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						buttons: {
							1: 'I pomeridiani',
							2: 'I notturni',
							3: 'I full-day',
						},
					},
				],
			},
			{
				title: '',
				questions: [],
				treatmentOnly: true,
			},
			// { // NON ABBIAMO I DATI DEL WEEKEND!
			// 	title: '',
			// 	questions: [],
			// 	treatmentOnly: true,
			// },
		],
		intro: {
			title: 'Tu, la tua casa e la tua famiglia',
		},
		end: {
		},
	},
	{
		title: 'Si può sempre migliorare',
		date0: '2024-02-26',
		date1: '2024-03-03',
		pages: [
			{
				title: 'Ecco una serie di azioni di risparmio energetico: per ciascuna, vorremmo sapere se viene adottata in casa tua.',
				questionSubtext1: 'Tu lo fai?',
				questionSubtext2: 'E il tuo familiare che partecipa allo studio, lo fa?',
				questions: [
					{
						text: 'Spegnere le luci quando esci da una stanza',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Spegnere la TV se sono al computer',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Non lasciare gli apparecchi in standby quando non in uso',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'In estate, impostare il termostato dell\'aria condizionata a non meno di 25 gradi',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Non tenere aperto il frigorifero mentre decido cosa mangiare',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Staccare il caricabatterie dalla presa se non in uso',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
				],
			},
			// {
			// 	title: 'Quali sono secondo te le fonti di spreco energetico nella tua casa?',
			// 	questions: [
			// 		{
			// 			text: 'L\'uso inefficiente degli elettrodomestici è una fonte di spreco?',
			// 			type: QuestionObject.TYPE_BUTTONS,
			// 			buttons: {
			// 				1: 'sì',
			// 				0: 'no',
			// 			},
			// 			title: 'Elettrodomestici'
			// 		},
			// 		{
			// 			text: 'Chi può fare qualcosa per ridurre questa fonte di spreco?',
			// 			type: QuestionObject.TYPE_CHECKBOXES,
			// 			buttons: {
			// 				1: 'Tu',
			// 				2: 'Il tuo familiare che partecipa allo studio',
			// 				3: 'Altri familiari',
			// 				4: 'Nessuno',
			// 			}
			// 		},
			// 		{
			// 			text: 'La disattenzione nello spegnere le luci è una fonte di spreco?',
			// 			type: QuestionObject.TYPE_BUTTONS,
			// 			buttons: {
			// 				1: 'sì',
			// 				0: 'no',
			// 			},
			// 			title: 'Luci'
			// 		},
			// 		{
			// 			text: 'Chi può fare qualcosa per ridurre questa fonte di spreco?',
			// 			type: QuestionObject.TYPE_CHECKBOXES,
			// 			buttons: {
			// 				1: 'Tu',
			// 				2: 'Il tuo familiare che partecipa allo studio',
			// 				3: 'Altri familiari',
			// 				4: 'Nessuno',
			// 			}
			// 		},
			// 		{
			// 			text: 'L\'uso inefficiente dell\'aria condizionata è una fonte di spreco?',
			// 			type: QuestionObject.TYPE_BUTTONS,
			// 			buttons: {
			// 				1: 'sì',
			// 				0: 'no',
			// 			},
			// 			title: 'Aria condizionata'
			// 		},
			// 		{
			// 			text: 'Chi può fare qualcosa per ridurre questa fonte di spreco?',
			// 			type: QuestionObject.TYPE_CHECKBOXES,
			// 			buttons: {
			// 				1: 'Tu',
			// 				2: 'Il tuo familiare che partecipa allo studio',
			// 				3: 'Altri familiari',
			// 				4: 'Nessuno',
			// 			}
			// 		},
			// 	],
			// },
			{
				title: 'Quali sono secondo te le maggiori fonti di spreco energetico nella tua casa?',
				questions: [
					{
						text: 'Mettile in ordine dalla maggiore alla minore.',
						type: QuestionObject.TYPE_DRAG_AND_ORDER,
						elements: {
							1: 'Uso inefficiente degli elettrodomestici',
							2: 'Disattenzione nello spegnere le luci',
							3: 'Uso inefficiente dell’aria condizionata',
						},
					},
                    {
                        text: 'Chi può fare di più per ridurre gli sprechi di energia in casa?',
                        type: QuestionObject.TYPE_CHECKBOXES,
                        buttons: {
                            1: 'Tu',
                            2: 'Il tuo familiare che partecipa allo studio',
                            3: 'Altri familiari',
                            4: 'Nessuno',
                        }
                    },
				]
			},
			{
				title: 'Quanti <b>Watt</b> pensi che consumino in 1 ora i seguenti apparecchi? Per aiutarti, ricorda che una lampadina da 100 Watt consuma 100 Watt in un\'ora.',
				questions: [
					{
						text: 'Caricabatterie per smartphone',
						type: QuestionObject.TYPE_SLIDER,
						min: 1,
						max: 100,
					},
					{
						text: 'TV',
						type: QuestionObject.TYPE_SLIDER,
						min: 10,
						max: 500,
					},
					{
						text: 'Frigorifero',
						type: QuestionObject.TYPE_SLIDER,
						min: 100,
						max: 800,
					},
					{
						text: 'Lavatrice',
						type: QuestionObject.TYPE_SLIDER,
						min: 100,
						max: 1000,
					},
					{
						text: 'Lavastoviglie',
						type: QuestionObject.TYPE_SLIDER,
						min: 500,
						max: 2000,
					},
					{
						text: 'Forno',
						type: QuestionObject.TYPE_SLIDER,
						min: 1000,
						max: 5000,
					},
				],
				// if:{'module2_page3_question3':[1,2]}
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_VIDEO,
						videoContent: {
							description: "Il consumo degli elettrodomestici",
							url: "https://vz-2baff7c4-a66.b-cdn.net/59f1eae2-d3eb-4be9-8b5c-99affd449a5b/original",
							thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/59f1eae2-d3eb-4be9-8b5c-99affd449a5b/thumbnail.jpg"
						}
					},
				],
				treatmentOnly: true,
				// if:{'module2_page3_question3': [3,4,5]}
			},
		],
		intro: {
			title: 'Non è poi così difficile risparmiare energia in casa',
		},
		end: {
		},
	},
	{
		title: 'Non ti scordar di me',
		date0: '2024-03-04',
		date1: '2024-03-10',
		pages: [
			{
				title: 'Ricordi le azioni di risparmio energetico di cui abbiamo parlato la scorsa settimana? Vorremmo sapere se hai cambiato i tuoi comportamenti.',
				questionSubtext1: 'Conferma, lo fai?',
				questionSubtext2: 'Quale di questi problemi ti impedisce di farla?',
				questions: [
					{
						text: 'Spegnere le luci quando esci da una stanza',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Spegnere la TV se sono al computer',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Non lasciare gli apparecchi in standby quando non in uso',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Non tenere aperto il frigorifero mentre decido cosa mangiare',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
					{
						text: 'Staccare il caricabatterie dalla presa se non in uso',
						type: QuestionObject.TYPE_SELECTS_TWO,
					},
				],
			},
			{
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'In generale, pensi di essere distratto quando si tratta di comportamenti efficienti?',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
			{
				if: {
					module4_page2_quest0: [1, 2],
				},
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'Come mantieni l\'attenzione nei tuoi comportamenti efficienti?',
						type: QuestionObject.TYPE_CHECKBOXES,
						buttons: {
							1: 'Sono parte delle mie abitudini fin da piccolo',
							2: 'Impostando dei promemoria',
							3: 'Metto impostazioni predefinite efficienti sugli elettrodomestici',
							4: 'Usare tecnologie che mostrino in tempo reale il consumo di energia',
							5: 'Altro (specificare)',
						},
					},
				],
			},
			{
				if: {
					module4_page2_quest0: [3, 4, 5],
				},
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'Che soluzioni pensi che sarebbero efficaci contro questo tipo di distrazione?',
						type: QuestionObject.TYPE_CHECKBOXES,
						buttons: {
							1: 'Impostare dei promemoria',
							2: 'Avere impostazioni predefinite efficienti per elettrodomestici e altri apparecchi elettrici',
							3: 'Usare tecnologie che mostrino in tempo reale il consumo di energia',
							4: 'Altro (specificare)',
						},
					},
				],
			},
			{
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'In generale, tendi a rimandare l’adozione di comportamenti efficienti?',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
			{
				if: {
					module4_page5_quest0: [1, 2],
				},
				title: 'Rispondi alla domanda:',
				questions: [
					{
						text: 'Tra le seguenti strategie, quali si sono dimostrate più efficaci per te contro la tendenza a rimandare?',
						type: QuestionObject.TYPE_CHECKBOXES,
						buttons: {
							1: 'Fare investimenti in efficienza energetica',
							2: 'Formulare dei piani',
							3: 'Porsi degli obiettivi',
							4: 'Imporsi delle scadenze',
							5: 'Altro (specificare)',
						},
					},
				],
			},
			{
				if: {
					module4_page5_quest0: [3, 4, 5],
				},
				title: 'Rispondi alla domanda:',
				questions: [
					// {
					// 	text: 'E ti dispiace di aver rimandato l\'adozione di comportamenti efficienti?',
					// 	type: QuestionObject.TYPE_BUTTONS,
					// 	buttons: {
					// 		1: 'sì',
					// 		0: 'no',
					// 	},
					// },
					{
						text: 'Tra le seguenti strategie, quali pensi siano le più efficaci contro questa tendenza a rimandare?',
						type: QuestionObject.TYPE_CHECKBOXES,
						buttons: {
							1: 'Fare investimenti in efficienza energetica',
							2: 'Formulare dei piani',
							3: 'Porsi degli obiettivi',
							4: 'Imporsi delle scadenze',
							5: 'Altro (specificare)',
						},
					},
				],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_VIDEO,
						videoContent: {
							description: "L'energia merita attenzione",
							url: "https://vz-2baff7c4-a66.b-cdn.net/7471f88a-67aa-410c-a204-a709a6eed2ca/original",
							thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/7471f88a-67aa-410c-a204-a709a6eed2ca/thumbnail.jpg"
						}
					},
				],
				treatmentOnly: true,
			},
			// {
			// 	title: 'Rispondi alla domanda:',
			// 	questions: [
			// 		{
			// 			text: 'Dopo aver visto il video, come riordineresti gli elementi?',
			// 			type: QuestionObject.TYPE_DRAG_AND_ORDER,
			// 			elements: {
			// 				1: 'Fare investimenti in efficienza energetica',
			// 				2: 'Formulare dei piani',
			// 				3: 'Porsi degli obiettivi',
			// 				4: 'Imporsi delle scadenze',
			// 				5: 'Altro',
			// 			},
			// 		},
			// 	],
			// 	treatmentOnly: true,
			// },
			{
				title: 'Qui sotto puoi vedere la tabella che hai compilato qualche settimana fa, dove hai indicato quando sei a casa in una settimana tipo e che apparecchi utilizzi.',
				questions: [
					{
						text: 'Spegnere la TV se sono al computer',
						type: QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE,
					},
					{
						text: 'Non lasciare gli apparecchi elettronici in standby quando non in uso',
						type: QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE,
					},
					{
						text: 'Impostare il termostato dell\'aria condizionata a non meno di 25 gradi',
						type: QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE,
					},
					{
						text: 'Staccare il caricabatterie dalla presa se non in uso',
						type: QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE,
					},
				],
			},
			// {
			// 	title: '',
			// 	questions: [
			// 		{
			// 			text: '',
			// 			type: QuestionObject.TYPE_PLANNER_PROMPT,
			// 			buttons: {
			// 				1: 'sì',
			// 				0: 'no',
			// 			},
			// 		},
			// 	],
			// },
		],
		intro: {
			title: 'Occhio alla distrazione e alla tendenza a rimandare!',
		},
		end: {
		},
	},
	{
		title: 'Ma quante ne sai?',
		date0: '2024-03-11',
		date1: '2024-03-17',
		pages: [
			{
				title: 'Quiz sui contenuti dei moduli precedenti',
				questions: [
					{
						text: 'Quanto pensi sia stata la tua carbon footprint nel mese di novembre 2023 rispetto a famiglie vicine simili alla tua?',
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						buttons: { // da ottenere rispetto al grafico del modulo 1
							1: 'Abbiamo consumato più della media',
							2: 'Abbiamo consumato un po\' meno della media',
							3: 'Rientriamo nelle famiglie più efficienti',
						},
					},
					{
						text: 'Quale di questi elettrodomestici è responsabile di una quota maggiore del totale dei consumi energetici di una famiglia tipo?',
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						buttons: {
							1: 'Lampadina',
							2: 'Lavatrice', // corretta
							3: 'Caricabatterie',
							4: 'Televisore',
						},
					},
					{
						text: 'Quale delle seguenti strategie è stata dimostrata essere efficace per risolvere il problema della scarsa attenzione ai consumi energetici?',
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						buttons: {
							1: 'Pagare le bollette in tempo',
							2: 'Prendere decisioni in date salienti ed in anticipo', // corretta
							3: 'Delegare ad un familiare più attento',
						},
					},
				],
			},
			{
				title: 'Ecco come sei andato!',
				questions: [
				],
			},
			{
				title: 'Rispondi alle domande:',
				questions: [
					{
						text: 'Avete parlato dei contenuti della piattaforma in famiglia?',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
			{
				title: 'Quali dei membri della tua famiglia hanno cambiato i loro comportamenti per risparmiare energia? Ad esempio nell\'utilizzo attento di elettrodomestici, luci e apparecchi elettrici',
				questions: [
					{
						text: 'Io',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Altro partecipante',
						type: QuestionObject.TYPE_LIKERT,
					},
					{
						text: 'Altri',
						type: QuestionObject.TYPE_LIKERT,
					},
				],
			},
			{
				title: 'Abbiamo calcolato un indice di interazione e comunicazione nella tua famiglia.<br>Per calcolarlo, abbiamo considerato quanto tu e il tuo familiare sapete l’uno dell’altro e siete d’accordo sul valore dell’ambiente, in base alle risposte che avete dato nelle missioni precedenti.<br><br>',
				questions: [
				],
			},
			{
				title: '',
				questions: [
					{
						type: QuestionObject.TYPE_VIDEO,
						videoContent: {
							description: "Cooperare per fare scelte migliori",
							url: "https://vz-2baff7c4-a66.b-cdn.net/1037c384-ebf0-4a06-bfbb-8775a734c447/original",
							thumbnail: "https://vz-2baff7c4-a66.b-cdn.net/1037c384-ebf0-4a06-bfbb-8775a734c447/thumbnail.jpg"
						}
					},
				],
				treatmentOnly: true,
			},
		],
		intro: {
			title: 'A questo punto del percorso, non ti ferma più nessuno',
		},
		end: {
		},
	},
	{
		title: 'Fino alla fine',
		date0: '2024-03-18',
		date1: '2024-03-24',
		pages: [
			{
				title: 'È stato utile?',
				questionSubtext1: 'Lo hai trovato utile per migliorare la consapevolezza dei consumi energetici della tua famiglia?',
				questionSubtext2: 'Ti ha fornito informazioni di cui non eri a conoscenza prima?',
				questionSubtext3: 'Hai parlato dei contenuti con l’altro partecipante in famiglia?',
				questions: [
					{
						text: 'Missione 0: Mettersi comodi',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
					{
						text: 'Missione 1: Cara energia...',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
					{
						text: 'Missione 2: Ritratto di famiglia',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
					{
						text: 'Missione 3: Si può sempre migliorare',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
					{
						text: 'Missione 4: Non ti scordar di me',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
					{
						text: 'Missione 5: Ma quante ne sai?',
						type: QuestionObject.TYPE_SELECTS_THREE,
					},
				],
			},
			{
				title: 'È stato utile?',
				questionSubtext1: 'Spegnere le luci quando esci da una stanza',
				questionSubtext2: 'Spegnere la TV se sono al computer',
				questionSubtext3: 'Non lasciare gli apparecchi in standby quando non in uso',
				questionSubtext4: 'Non tenere aperto il frigorifero mentre decido cosa mangiare',
				questionSubtext5: 'Staccare il caricabatterie dalla presa se non in uso',
				questionSubtext6: 'Abbassare la temperatura di lavaggio o usare programmi eco per lavatrice e lavastoviglie',
				questionSubtext7: 'Alzare la temperatura di frigorifero e freezer',
				questions: [
					{
						text: 'Hai dei suggerimenti per rendere la piattaforma più utile ed efficace?',
						type: QuestionObject.TYPE_TEXT,
					},
					{
						text: 'Hai adottato delle strategie per risparmiare energia?',
						type: QuestionObject.TYPE_SELECTS_SIX, // actually five ahaha
					},
					{
						text: 'Quanta elettricità pensi che la tua famiglia abbia consumato nell\'ultimo mese? <span class="text-lightcoral">(kWh)</span>',
						type: QuestionObject.TYPE_SLIDER,
						min: 30,
						max: 300,
					},
					{
						text: 'Pensi che la carbon footprint dei consumi elettrici della tua famiglia nell\'ultimo mese sia diminuita, aumentata o rimasta uguale rispetto Novembre 2023?',
						type: QuestionObject.TYPE_BUTTONS,
						buttons: {
							1: 'diminuita',
							2: 'aumentata',
							3: 'invariata',
						},
					},
				],
			},
			{
				title: '',
				questions: [],
				treatmentOnly: true,
			},
		],
		intro: {
			title: 'Ultimissima cosa: ti va di lasciarci qualche feedback?',
		},
		end: {
		},
	},
]
