import {ref, unref} from 'vue';
import {browserDt} from '@/libraries/utils.js';
import {useMainStateStore} from '@/stores/mainState.js';
import {ModuleObject} from '@/dataObjects/ModuleObject.js';

export class QuestionObject {
	//super types !! (used to group types behavior)
	static SUPERTYPE_QUESTION = 'question'
	static SUPERTYPE_VIDEO = 'video'
	static SUPERTYPE_GAME = 'game'
	static SUPERTYPE_PLANNER = 'planner'
	//types
	static TYPE_VIDEO = 'video'
	static TYPE_GAME_SLIDER = 'game_slider'
	static TYPE_GAME_CHOICE = 'game_choice'
	static TYPE_GAME_CODE = 'game_code'
	static TYPE_LIKERT = 'likert'
	static TYPE_SLIDER = 'slider'
	static TYPE_BUTTONS = 'buttons'
	static TYPE_INCREASE_DECREASE = 'increase_decrease'
	static TYPE_DAYPART_BUTTONS = 'daypart_buttons'
	static TYPE_DAYPART_BUTTONS_AND_NONE = 'daypart_buttons_and_none'
	static TYPE_DRAG_AND_DROP = 'drag_and_drop'
	static TYPE_LARGE_BUTTONS = 'large_buttons'
	static TYPE_SELECTS_TWO = 'selects_two'
	static TYPE_SELECTS_THREE = 'selects_three'
	static TYPE_SELECTS_SIX = 'selects_six'
	static TYPE_CHECKBOXES = 'checkboxes'
	static TYPE_DRAG_AND_ORDER = 'drag_and_order'
	static TYPE_PLANNER_PROMPT = 'planner_prompt'
	static TYPE_TEXT = 'text'
	//answer values
	static ANSWER_VALUE_FAKE_ANSWER = '99999999999' //debug
	static ANSWER_VALUE_NOT_SEEN = '-2'
	static ANSWER_VALUE_SEEN = '-1'
	static ANSWER_VALUE_VIDEO_COMPLETED = '0'
	static ANSWER_VALUE_GAME_COMPLETED = '0'
	static ANSWER_VALUES_TYPE_DAYPART_BUTTONS = {
		0: 'mai', 1: 'mattino', 2: 'pomeriggio', 3: 'sera', 4: 'notte',
	}
	static ANSWER_VALUES_TYPE_DAYPART_BUTTONS_AND_NONE = {
		0: 'non desidero un promemoria', 1: 'mattino', 2: 'pomeriggio', 3: 'sera', 4: 'notte',
	}
	static ANSWER_KEYS_TYPE_TWO_SELECTS = ['1', '2']
	static ANSWER_KEYS_TYPE_THREE_SELECTS = ['1', '2', '3']
	static ANSWER_KEYS_TYPE_SIX_SELECTS = ['1', '2', '3', '4', '5', '6', '7']
	static ANSWER_VALUES_TYPE_TWO_SELECTS = {
		0: 'no', 1: 'sì', 2: 'non lo so', 3: 'non ho l\'aria condizionata',
        4: 'non ho problemi', 5: 'mi dimentico', 6: 'tendo a rimandare', 7: 'non penso che sia importante', 8: 'è troppo difficile'
	}
	static ANSWER_VALUES_TYPE_THREE_SELECTS = {
		0: 'no', 1: 'sì', 2: 'non lo so',
	}
	/**
	 * formato delle risposte multiple
	 * lista value10;value20;value40
	 * oggetto key1:value10;key2:value20;key3:value30
	 * oggetto con liste
	 * key1:value10,value20,value30;key2:value10,value20,value30
   */
	static ANSWER_SEPARATOR_VALUES = ';'
	static ANSWER_SEPARATOR_KEYVALUE = ':'
	static ANSWER_SEPARATOR_SUBVALUES = ','

    // ! GAME CHOICE CODEMAPPING
    // --- FUNCTIONS USED TO GENERATE THE CODES ---
    // const sha256 = async (message) => {
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(message);
    //     const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    //     return hashHex;
    // };
    // const _generateCode = async (prefix, separator, start, end) => {
    //     const codes = [];
    //     for (let i = start; i <= end; i++) {
    //         const code = `${prefix}${separator}${i}`;
    //         const sha256Hash = await sha256(code);
    //         codes.push({ code, sha256Hash });
    //     }
    //     return codes;
    // };
    // const codesList = await _generateCode("SPINA", "_", 0, 40);
    // codesList.forEach(({ code, sha256Hash }) => {
    //     console.log(`${code}: ${sha256Hash.slice(0, 3)}-${sha256Hash.slice(3, 6)}`);
    // });

    static CODE_MAPPING = { // use the codeMapping function!!!
        0: "29a-176",
        1: "86e-5b6",
        2: "ebe-2c1",
        3: "954-fbb",
        4: "02d-834",
        5: "f8f-d40",
        6: "0d6-784",
        7: "f1e-2ab",
        8: "840-05e",
        9: "9ee-b03",
        10: "df5-24d",
        11: "223-685",
        12: "332-46a",
        13: "7ed-0cd",
        14: "ca0-f77",
        15: "4fd-aef",
        16: "913-f1a",
        17: "2b5-3f8",
        18: "eca-23e",
        19: "cd7-ad5",
        20: "f36-c44",
        21: "aed-2e7",
        22: "f14-1ad",
        23: "642-f71",
        24: "3f0-10f",
        25: "dbd-135",
        26: "945-380",
        27: "d06-f25",
        28: "be8-2f1",
        29: "4f2-25f",
        30: "7bc-ced",
        31: "48b-497",
        32: "c50-e96",
        33: "c63-c94",
        34: "ce8-6ab",
        35: "d2b-641",
        36: "48b-907",
        37: "354-e5a",
        38: "bdd-519",
        39: "86b-c38",
        40: "cfb-a86"
    };
    static codeMapping(input, action='obtain'){
        // this exists to make sure a user doesn't input their own code, and to make sure two users of the same family have different codes.
        // ---
        // action='obtain': from score to code, input is score
        // action='check': from code to score, input is code
        // action='checkBack': from score to code INVERTED, input is code
        // ---
        // the adult OBTAINS a code with an "x" in the third place (a letter that never shows up in the third position)
        // the adult CHECKS the vanilla code
        // the youth CHECKS a code with an "x" in the third place
        // the youth OBTAINS the vanilla code

        console.debug("[codeMapping] ", input, action)

        const _flip = (data) => Object.fromEntries(
            Object
              .entries(data)
              .map(([key, value]) => [value, key])
        );

        let userType = useMainStateStore().user.is_youth_or_adult
        // invert if checkBack
        if(action=="checkBack"){
            userType = (userType=='adult')?'youth':'adult'
            action = 'obtain'
            console.debug("[codeMapping] checkBack! userType is now inverted. Action is now 'obtain'.")
        }

        if (userType=='adult'){
            if (action=='obtain'){
                // input is always good
                let score = parseInt(input)
                let code = this.CODE_MAPPING[score]
                code = code.split('');
                code[2] = 'x';
                code = code.join('');
                console.debug("[codeMapping] adult-obtain: ", score, '->', code)
                return code
            } else if (action='check'){
                // input is not trustworthy
                let code = input.toLowerCase()
                let flippedMapping = _flip(this.CODE_MAPPING)
                let score = -1 // ! error value
                if (code in flippedMapping){
                    score = flippedMapping[code]
                }
                console.debug("[codeMapping] adult-check: ", code, '->', score)
                return score
            }
        } else if (userType=='youth'){
            if (action=='obtain'){
                // input is always good
                let score = parseInt(input)
                let code = this.CODE_MAPPING[score]
                console.debug("[codeMapping] youth-obtain: ", score, '->', code)
                return code
            } else if (action='check'){
                // input is not trustworthy
                let code = input.toLowerCase()
                let flippedMapping = _flip(this.CODE_MAPPING)
                let score = -1 // ! error value
                for (const [key, value] of Object.entries(flippedMapping)) {
                    let maybeCode = key
                    maybeCode = maybeCode.split('');
                    maybeCode[2] = 'x';
                    maybeCode = maybeCode.join('');
                    if(code==maybeCode){
                        score = value
                    }
                }
                console.debug("[codeMapping] adult-check: ", code, '->', score)
                return score
            }
        }

    }

	/**
	 * @param questionData {Object}
	 * @param parent {PageObject}
	 * @param props {Object}
	 */
	constructor(questionData, parent, props = {}) {
		this.parent = parent
		this.index = props.index
		this.isFirst = props.isFirst
		this.isLast = props.isLast
		this.id = `${this.parent.id}_quest${this.index}`
		this.type = questionData.type
		this.supertype = this.getSupertypeByType(questionData.type)
		this.text = questionData.text
		this.text1 = questionData.text1
		this.text2 = questionData.text2
		//default answer values
		this.answerValue = QuestionObject.ANSWER_VALUE_NOT_SEEN
		this.dbAnswerValue = QuestionObject.ANSWER_VALUE_NOT_SEEN
		this.answerScore = 0
		/**
		 * todo trasformare la variabile isDirty in una funzione che controlla l'uguaglianza tra this.dbAnswerValue e this.answerValue
		 *    //poi aggiungervi questo controllo
		 * 		if(!this.isDirty && this.dbAnswerValue !== this.answerValue) {
		 * 			console.log('bad isDirty 1', this.dbAnswerValue , this.answerValue)
		 * 		}
		 * 		else if(this.isDirty && this.dbAnswerValue === this.answerValue) {
		 * 			console.log('bad isDirty 2', this.dbAnswerValue , this.answerValue)
		 * 		}
		 */
		//se true, la risposta non e' stata ancora salvata sul db remoto
		this.isDirty = false
		this.dt = null

		if(questionData.buttons) {
			this.buttons = questionData.buttons
		}
		else if(questionData.elements) {
			this.elements = questionData.elements
		}

		this.title = questionData.title
		this.answerValueText = null

		//props by type
		if(this.type === QuestionObject.TYPE_VIDEO) {
			this.videoContent = questionData.videoContent
		}
		else if(this.type === QuestionObject.TYPE_SLIDER) {
			this.min = questionData.min
			this.max = questionData.max
		}
		else if(this.type === QuestionObject.TYPE_SELECTS_TWO) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
		}
		else if(this.type === QuestionObject.TYPE_SELECTS_THREE) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
			this.subtext3 = this.parent.questionSubtext3
		}
		else if(this.type === QuestionObject.TYPE_SELECTS_SIX) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
			this.subtext3 = this.parent.questionSubtext3
			this.subtext4 = this.parent.questionSubtext4
			this.subtext5 = this.parent.questionSubtext5
			this.subtext6 = this.parent.questionSubtext6
			this.subtext7 = this.parent.questionSubtext7
		}
		this.isSelectsType = (
			this.type === QuestionObject.TYPE_SELECTS_TWO ||
			this.type === QuestionObject.TYPE_SELECTS_THREE ||
			this.type === QuestionObject.TYPE_SELECTS_SIX
		)
		this.hasMultipleAnswers = (
			questionData.type === QuestionObject.TYPE_DAYPART_BUTTONS ||
			questionData.type === QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE ||
			questionData.type === QuestionObject.TYPE_CHECKBOXES ||
			questionData.type === QuestionObject.TYPE_DRAG_AND_DROP ||
			this.isSelectsType
		)
		this.hasMultipleSimpleAnswers = (
			questionData.type === QuestionObject.TYPE_DAYPART_BUTTONS ||
			questionData.type === QuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE ||
			questionData.type === QuestionObject.TYPE_CHECKBOXES
		)
	}

	getSupertypeByType(type) {
		switch(type) {
			case QuestionObject.TYPE_VIDEO: return QuestionObject.SUPERTYPE_VIDEO;
			case QuestionObject.TYPE_GAME_SLIDER : return QuestionObject.SUPERTYPE_GAME;
			case QuestionObject.TYPE_GAME_CHOICE : return QuestionObject.SUPERTYPE_GAME;
			case QuestionObject.TYPE_GAME_CODE : return QuestionObject.SUPERTYPE_GAME;
			case QuestionObject.TYPE_PLANNER_PROMPT : return QuestionObject.SUPERTYPE_PLANNER;
			default: return QuestionObject.SUPERTYPE_QUESTION;
		}
	}

	/**
	 * dati dal db
	 * @param answer {Object}
	 */
	hydrate(answer) {
		//init db value
		this.dbAnswerValue = answer.value
		//todo togliere ref?
		this.answerValue = ref(answer.value)
		this.answerValueText = answer.value_text
		this.answerScore = +answer.achievement?.score
		this.isDirty = false
		this.dt = answer.browser_dt
	}

	isAnswered(dbAnswersOnly = false, ignoreHammerShot = false){
		/**
		 * Un modulo è modificabile solo tra la data inizio e la data fine,
		 * questo significa disabilitarne tutte le domande e cambiarne i colori.
		 * Il frontend usa gia' Question.isAnswered per farlo nel caso normale di risposta
		 * presente, invece in questo caso simuliamo la presenza della risposta controllando
		 * lo stato del modulo.
		 * E' una martellata, ma l'alternativa e' fare decine di modifiche al frontend
		 * e siamo in zona cesarini del progetto.
		 * @see ModuleObject getState
		 */
		if(!ignoreHammerShot && this.parent.parent.state === ModuleObject.STATE_ACCESSIBLE_UNEDITABLE) {
			return true
		}

		const answerValue = this._getAnswerValue(dbAnswersOnly)

		if(
			answerValue === QuestionObject.ANSWER_VALUE_SEEN ||
			answerValue === QuestionObject.ANSWER_VALUE_NOT_SEEN
		) {
			return false
		}

		/**
		 * TYPE_SELECTS può avere una risposta non completa
		 */
		if(this.isSelectsType) {
			return this._typeSelect_isAnswerCompleted(answerValue)
		}

		return true
	}

	/**
	 * TYPE_SELECTS può avere una risposta parziale, non completa, la funzione conteggia
	 * il numero di risposte valide
	 *
	 * TYPE_SELECTS_TWO   la risposta PARZIALE è valida solo se la chiave è in (1,2)   e se il valore è in (0,1,2,3)
	 * TYPE_SELECTS_THREE la risposta PARZIALE è valida solo se la chiave è in (1,2,3) e se il valore è in (0,1,2)
	 * @param answerValue {String}
	 * @returns {Boolean}
	 * @private
	 */
	_typeSelect_isAnswerCompleted(answerValue) {
		let validAnswers, validValues, validKeys, validValuesNumber

		if(answerValue === QuestionObject.ANSWER_VALUE_SEEN) {
			return true
		}

		if(this.type === QuestionObject.TYPE_SELECTS_TWO) {
			validValuesNumber = 2
			validValues = Object.keys(QuestionObject.ANSWER_VALUES_TYPE_TWO_SELECTS)
			validKeys = QuestionObject.ANSWER_KEYS_TYPE_TWO_SELECTS
		}
		else if(this.type === QuestionObject.TYPE_SELECTS_THREE) {
			validValuesNumber = 3
			validValues = Object.keys(QuestionObject.ANSWER_VALUES_TYPE_THREE_SELECTS)
			validKeys = QuestionObject.ANSWER_KEYS_TYPE_THREE_SELECTS
		}
		else if(this.type === QuestionObject.TYPE_SELECTS_SIX) {
			validValuesNumber = 7
			//ebbene sì, SIX Ha le stesse risposte di TWO
			validValues = Object.keys(QuestionObject.ANSWER_VALUES_TYPE_TWO_SELECTS)
			validKeys = QuestionObject.ANSWER_KEYS_TYPE_SIX_SELECTS
		}

		//Object.keys restituisce un array di NOMI di prorietà, quindi un array di stringhe,
		//quindi checkValidity non ha problemi di confronto con answerValue
		const checkValidity = (key, value) => validKeys.includes(key) && validValues.includes(value)

		//https://stackoverflow.com/a/22482737/3740246
		if(Object(answerValue) === answerValue) {
			validAnswers = []
			//answerValue {key1: value10, key2: value40}
			for(let name in answerValue) {
				if(!answerValue.hasOwnProperty(name)) {
					continue
				}
				if(checkValidity(name, answerValue[name])) {
					//How to create an object property from a variable value in JavaScript?
					//ES6 introduces computed property names
					//https://stackoverflow.com/a/25333702/3740246
					validAnswers.push({
						[name]: answerValue[name]
					})
				}
			}
			return validAnswers
		}
		else {
			//answerValue 'key1:value10;key2:value30'
			validAnswers = answerValue.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
				//keyValueString 'key1:value10'
				.filter(keyValueString => {
					// keyValueArray ['key1','value10']
					const keyValueArray = keyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)

					return checkValidity(keyValueArray[0], keyValueArray[1])
				})
		}

		return validAnswers.length === validValuesNumber
	}

	/**
	 * search by key and value
	 * @param key
	 * @param value
	 * @param dbAnswersOnly
	 * @returns {boolean}
	 */
	hasThisAnswer(key, value, dbAnswersOnly = false) {
		let answerValue = this._getAnswerValue(dbAnswersOnly)

		return !!answerValue.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			//keyValueString 'key1:value10'
			.find(keyValueString => {
				// keyValueArray ['key1','value10']
				const keyValueArray = keyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)

				return keyValueArray[0] === key && keyValueArray[1] === value
			})
	}

	/**
	 * @param answerValue {string}
	 * @param dbAnswersOnly {boolean}
	 * @returns {boolean}
	 */
	hasThisAnswerValue(answerValue, dbAnswersOnly = false) {
		if(answerValue === undefined || answerValue === null || answerValue === '') {
			return false
		}

		//always strings
		answerValue = answerValue.toString()

		let thisAnswerValue = this._getAnswerValue(dbAnswersOnly)
		/**
		 * multirisposta semplice ('value1;value2;value3')
		 * divido thisAnswerValue in pezzi e vi cerco answerValue
		 */
		if(this.hasMultipleSimpleAnswers) {
			thisAnswerValue = thisAnswerValue
				.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
				.find(value => value === answerValue)
		}

		if(thisAnswerValue === null || thisAnswerValue === undefined) {
			return false
		}

		//always strings
		return thisAnswerValue.toString() === answerValue
	}

	hasThisAnswerKey(answerKey, dbAnswersOnly = false) {
		if(this.type !== QuestionObject.TYPE_DRAG_AND_DROP && !this.isSelectsType) {
			return
		}

		return this._getAnswerValue(dbAnswersOnly)
			.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			.some(keyValueString => {
				//oldKeyValueString 'key0:value30,value40' >> key0
				return keyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] === answerKey
			})
	}

	isSeen(dbAnswersOnly = false) {
		return this._getAnswerValue(dbAnswersOnly) === QuestionObject.ANSWER_VALUE_SEEN
	}

	isNotSeen(dbAnswersOnly = false) {
		return this._getAnswerValue(dbAnswersOnly) === QuestionObject.ANSWER_VALUE_NOT_SEEN
	}

	/**
	 * @param dbAnswersOnly {boolean} se true, considera solo la risposta salvata sul db remoto
	 * @returns {string}
	 */
	_getAnswerValue(dbAnswersOnly) {
		return dbAnswersOnly && this.isDirty
			? this.dbAnswerValue
			: this.answerValue
	}

	setAsSeen(force = false) {
		if(!force && this.isAnswered()) {
			return
		}
		this.setAnswer(QuestionObject.ANSWER_VALUE_SEEN)
	}

	/**
	 * la questione del dbAnswerOnly qui non si pone perche sto aggiornando
	 * l'instanza locale della domanda, l'aggiornamento del db avviene altrove
	 *
	 * hasMultipleAnswers
	 *   hasMultipleSimpleAnswers: 'value10;value20'
	 *   TYPE_DRAG_AND_DROP: {key1: [value10, value20]}
	 *   TYPE_SELECTS_TWO|TYPE_SELECTS_THREE: {key1: value10}
	 * @param answerValue {String|Object}
	 * @param force {Boolean} ignora la concatenazione multirisposta e sostituisce il valore come nelle domande normali
	 */
	setAnswer(answerValue, force = false) {
		//ignore same answerValue
		if(this.hasThisAnswerValue(answerValue)) {
			return
		}

		//multirisposta, imposto this.answerValue come la concatenazione del nuovo ai vecchi valori
		if(
			!force &&
			this.hasMultipleAnswers &&
			answerValue !== QuestionObject.ANSWER_VALUE_SEEN &&
			answerValue !== QuestionObject.ANSWER_VALUE_NOT_SEEN
		) {
			//SEEN e NOT_SEEN sono vecchi valori da sostituire senza concatenare
			//oldAnswerValueArray [oldValue1;oldValue2;oldValue3]
			const oldAnswerValueArray = (
				this.answerValue === QuestionObject.ANSWER_VALUE_SEEN ||
				this.answerValue === QuestionObject.ANSWER_VALUE_NOT_SEEN
			)
				? [] : this.answerValue.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			/**
			 * multirisposta semplice
			 * 'oldValue1;oldValue2' > 'oldValue1;oldValue2;newValue3'
			 */
			if(this.hasMultipleSimpleAnswers) {
				answerValue = oldAnswerValueArray
					//filtro in base al valore per non avere doppioni, non dovrebbe servire
					.filter(oldValue => oldValue !== answerValue)
					.concat([answerValue])
					.join(QuestionObject.ANSWER_SEPARATOR_VALUES)
			}
			else if(this.type === QuestionObject.TYPE_DRAG_AND_DROP) {
				if(typeof answerValue !== 'object') {
					console.error('bad answerValue', this.id, answerValue)
					return
				}

				//answerValue {key1: [value10, value20]}

				//newKeyValuesArray [key1, [value10, value20]]
				const newKeyValuesArray = Object.entries(answerValue)[0]
				// newKeyString 'key1'
				const newKeyString = newKeyValuesArray[0]
				// newValuesArray [value10, value20]
				// non mi fido dei controlli di frontend, unifico i valori https://stackoverflow.com/a/14438954/3740246
				const newValuesArray = newKeyValuesArray[1].filter((v,i,a) => a.indexOf(v) === i)
				//newValuesString 'value10,value20'
				const newValuesString = newValuesArray.join(QuestionObject.ANSWER_SEPARATOR_SUBVALUES)
				//newKeyValuesString 'key1:value10,value20'
				const newKeyValuesString = newKeyString + QuestionObject.ANSWER_SEPARATOR_KEYVALUE + newValuesString

				//answerValue risultante potrebbe essere 'key0:value30,value40;key1:value10,value20'
				answerValue = oldAnswerValueArray
					//filtro in base alla chiave per non avere doppioni, serve
					.filter(oldKeyValueString => {
						//oldKeyValueString 'key0:value30,value40' >> OK
						//oldKeyValueString 'key1:value30,value40' >> KO
						return oldKeyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== newKeyString
					})
					//aggiungo il nuovo valore
					.concat([newKeyValuesString])
					.join(QuestionObject.ANSWER_SEPARATOR_VALUES)
			}
			else if(this.isSelectsType) {
				//qui mi sono incartato, partire da TYPE_DRAG_AND_DROP non e' stata una buona idea
				if(typeof answerValue !== 'object') {
					console.error('bad answerValue', this.id, answerValue)
					return
				}
				//answerValue {key: value10}

				//newKeyValuesArray [key1, value10]
				const newKeyValuesArray = Object.entries(answerValue)[0]
				// newKeyString 'key1'
				const newKeyString = newKeyValuesArray[0]
				// newValuesString 'value10'
				const newValuesString = newKeyValuesArray[1]
				//newKeyValuesString 'key1:value10'
				const newKeyValuesString = newKeyString + QuestionObject.ANSWER_SEPARATOR_KEYVALUE + newValuesString

				//answerValue risultante potrebbe essere 'key0:value30;key1:value40'
				answerValue = oldAnswerValueArray
					//filtro in base alla chiave per non avere doppioni, serve
					.filter(oldKeyValueString => {
						//oldKeyValueString 'key0:value30' >> OK
						//oldKeyValueString 'key1:value40' >> KO
						return oldKeyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== newKeyString
					})
					//aggiungo il nuovo valore
					.concat([newKeyValuesString])
					.join(QuestionObject.ANSWER_SEPARATOR_VALUES)
			}
		}

		this.answerValue = answerValue
		this.isDirty = true
		this.dt = browserDt()
	}

	/**
	 * answerValueText non deve essere una stringa vuota, nel caso e' null
	 * @param answerValueText {?String}
	 */
	setAnswerText(answerValueText= null) {
		this.answerValueText = (answerValueText ? answerValueText.trim() : null) || null
	}

	/**
	 * di una multi risposta elimina la risposta, ricerca per valore
	 * @param answerValue
	 */
	removeAnswerByValue(answerValue) {
		if(!this.hasMultipleSimpleAnswers) {
			return
		}
		/**
		 * 'oldValue1;oldValue2' >> 'oldValue1'
		 */
		this.answerValue = this.answerValue.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			.filter(value => value !== answerValue)
			.join(QuestionObject.ANSWER_SEPARATOR_VALUES)

		this._fixEmptyAnswer()
	}

	toggleAnswer(answerValue) {
		if(this.hasThisAnswerValue(answerValue)) {
			this.removeAnswerByValue(answerValue)
			return false
		}
		else {
			this.setAnswer(answerValue)
			return true
		}
	}

	//solo TYPE_SELECTS, TYPE_DRAG_AND_DROP
	removeAnswerByKey(answerKey) {
		if(this.type !== QuestionObject.TYPE_DRAG_AND_DROP && !this.isSelectsType) {
			return
		}

		answerKey = answerKey.toString()

		//ignore different answerValue
		if(!this.hasThisAnswerKey(answerKey)) {
			return
		}

		this.answerValue = this.answerValue.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			.filter(keyValueString => {
				//oldKeyValueString 'key0:value30,value40' >> key0
				return keyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== answerKey
			})
			.join(QuestionObject.ANSWER_SEPARATOR_VALUES)

		this._fixEmptyAnswer()
	}

	/**
	 * da 'key0:value10,value20;key1:value30,value40'
	 * a [{key0: [value10, value20]}, {key1: [value30, value40]}]
	 */
	answerValuesToObjectFormat() {
		//solo multirisposta, TYPE_DRAG_AND_DROP
		if(this.type !== QuestionObject.TYPE_DRAG_AND_DROP) {
			return false
		}

		return this.answerValue
			// ['key0:value10,value20', 'key1:value30,value40']
			.split(QuestionObject.ANSWER_SEPARATOR_VALUES)
			.map(keyValueString => {
				const keyValueArray = keyValueString.split(QuestionObject.ANSWER_SEPARATOR_KEYVALUE)
				const keyString = keyValueArray[0]
				const valuesString = keyValueArray[1]
				//{key0: [value10, value20]}
				return {
					[keyString]: valuesString.split(QuestionObject.ANSWER_SEPARATOR_SUBVALUES)
				}
			})
	}

	/**
	 * se ho tolto l'ultimo valore dalla risposta multipla, il valore risultante e' ANSWER_VALUE_SEEN,
	 * ovvero la risposta di default di una domanda con cui l'utente ha gia' interagito
	 */
	_fixEmptyAnswer() {
		if(!this.answerValue) {
			this.answerValue = QuestionObject.ANSWER_VALUE_SEEN
			this.isDirty = false
		}
	}

	//formato per il salvataggio in db
	answerDbFormat() {
		return {
			total_question_id: this.id,
			module_id        : this.parent.parent.index,
			page_id          : this.parent.index,
			question_id      : this.index,
			question_type    : this.type,
			value            : this.answerValue,
			value_text       : this.answerValueText,
			browser_dt       : this.dt,
		}
	}

	canBeSavedToDb() {
		if(!this.isDirty) {
			return false
		}

		/**
		 * TYPE_SELECTS può avere una risposta parziale, che NON deve essere salvata in db
		 */
		if(this.isSelectsType) {
			return this._typeSelect_isAnswerCompleted(this.answerValue)
		}

		return true
	}

	mustConfirmAnswer() {
		//video non hanno bisogno di conferma
		if(
			this.supertype !== QuestionObject.SUPERTYPE_QUESTION &&
			this.supertype !== QuestionObject.SUPERTYPE_GAME &&
			this.supertype !== QuestionObject.SUPERTYPE_PLANNER
		) {
			return false
		}

		return this.isDirty && this.isAnswered()
	}

	//debug start
	complete() {
		this.setAnswer(QuestionObject.ANSWER_VALUE_FAKE_ANSWER)
	}
	notSeen() {
		this.setAnswer(QuestionObject.ANSWER_VALUE_NOT_SEEN)
	}
	//debug end

	//todo funzione che restituisce lo stato isAlreadyConfirmed
	//  per disattivare tutta setAnswer, guarda mustConfirmAnswer e questionButtons.vue
}
