<script setup>
</script>
<template >
	<svg width="290" height="96" viewBox="0 0 290 96" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1025_30561)">
			<path d="M99.6769 55.6464C97.1332 54.4927 95.1195 52.9229 93.6412 50.9316C92.1574 48.9458 91.3709 46.7215 91.2705 44.2642H100.012C100.162 45.9838 100.988 47.4204 102.494 48.5742C104 49.7279 105.891 50.3048 108.161 50.3048C110.432 50.3048 112.373 49.8499 113.678 48.9402C114.983 48.0306 115.642 46.8657 115.642 45.4402C115.642 43.9203 114.911 42.7887 113.455 42.051C111.999 41.3133 109.69 40.5034 106.527 39.6215C103.464 38.7894 100.971 37.9796 99.0465 37.1919C97.1221 36.4043 95.4542 35.2006 94.0485 33.5809C92.6428 31.9612 91.9399 29.8257 91.9399 27.1743C91.9399 25.0165 92.5814 23.0363 93.8644 21.2446C95.1474 19.453 96.9882 18.0385 99.3812 17.0068C101.774 15.9751 104.53 15.4592 107.643 15.4592C112.284 15.4592 116.027 16.6241 118.866 18.9593C121.705 21.2946 123.222 24.4729 123.423 28.5H114.978C114.827 26.6861 114.091 25.2328 112.758 24.1567C111.425 23.0751 109.623 22.5371 107.352 22.5371C105.082 22.5371 103.425 22.9531 102.243 23.7907C101.06 24.6282 100.463 25.7321 100.463 27.1077C100.463 28.1893 100.859 29.0935 101.646 29.8312C102.438 30.569 103.398 31.1458 104.535 31.5618C105.673 31.9779 107.352 32.5104 109.573 33.1427C112.535 33.9304 114.967 34.7236 116.869 35.539C118.771 36.3488 120.411 37.5414 121.794 39.1112C123.178 40.6809 123.892 42.7721 123.942 45.3736C123.942 47.6811 123.301 49.7445 122.018 51.5584C120.735 53.3778 118.916 54.7978 116.573 55.8295C114.225 56.8612 111.475 57.3771 108.312 57.3771C105.149 57.3771 102.226 56.8002 99.6824 55.6464H99.6769Z" fill="#326DE3"/>
			<path d="M147.343 17.3841C149.814 16.1084 152.603 15.4705 155.71 15.4705C159.263 15.4705 162.51 16.3413 165.45 18.083C168.389 19.8248 170.693 22.2709 172.378 25.4105C174.057 28.5556 174.894 32.161 174.894 36.238C174.894 40.315 174.051 43.9593 172.378 47.1765C170.699 50.3937 168.389 52.8953 165.45 54.687C162.51 56.4786 159.263 57.3772 155.71 57.3772C152.598 57.3772 149.831 56.7504 147.415 55.4968C144.994 54.2432 143.02 52.6845 141.491 50.8208V76.0092H133.046V16.1305H141.491V22.099C142.925 20.2352 144.872 18.6599 147.343 17.3841ZM164.563 28.9827C163.403 26.947 161.869 25.3994 159.972 24.3399C158.07 23.286 156.034 22.7591 153.858 22.7591C151.683 22.7591 149.719 23.3027 147.823 24.3788C145.92 25.4604 144.392 27.0302 143.232 29.0936C142.071 31.1571 141.491 33.5866 141.491 36.3878C141.491 39.189 142.071 41.6296 143.232 43.7152C144.392 45.8008 145.92 47.3873 147.823 48.4634C149.725 49.545 151.733 50.083 153.858 50.083C155.984 50.083 158.07 49.5284 159.972 48.4245C161.874 47.3207 163.403 45.7121 164.563 43.5987C165.723 41.4909 166.303 39.0336 166.303 36.2325C166.303 33.4313 165.723 31.0184 164.563 28.9771V28.9827Z" fill="#326DE3"/>
			<path d="M183.713 9.2079C182.675 8.17618 182.156 6.9004 182.156 5.38055C182.156 3.8607 182.675 2.57937 183.713 1.5532C184.75 0.521476 186.033 0.00561523 187.562 0.00561523C189.09 0.00561523 190.3 0.521476 191.338 1.5532C192.376 2.58492 192.894 3.8607 192.894 5.38055C192.894 6.9004 192.376 8.18173 191.338 9.2079C190.3 10.2396 189.04 10.7555 187.562 10.7555C186.083 10.7555 184.745 10.2396 183.713 9.2079ZM191.712 16.1304V56.7114H183.266V16.1304H191.712Z" fill="#326DE3"/>
			<path d="M232.037 17.4562C234.58 18.782 236.566 20.7456 238 23.347C239.433 25.9485 240.147 29.0936 240.147 32.7768V56.7116H231.78V34.0248C231.78 30.3916 230.865 27.6071 229.041 25.6656C227.211 23.7242 224.718 22.7535 221.561 22.7535C218.403 22.7535 215.893 23.7242 214.041 25.6656C212.189 27.6071 211.263 30.3916 211.263 34.0248V56.7116H202.818V16.1305H211.263V20.7733C212.647 19.1037 214.409 17.8057 216.557 16.8683C218.705 15.9364 220.986 15.4705 223.407 15.4705C226.615 15.4705 229.493 16.1361 232.037 17.4618V17.4562Z" fill="#326DE3"/>
			<path d="M250.629 25.4105C252.33 22.2709 254.651 19.8248 257.59 18.083C260.53 16.3413 263.777 15.4705 267.33 15.4705C270.537 15.4705 273.343 16.0973 275.736 17.3509C278.129 18.6045 280.043 20.1631 281.476 22.0269V16.1361H289.994V56.7171H281.476V50.6766C280.043 52.5902 278.096 54.1877 275.625 55.4635C273.154 56.7393 270.342 57.3772 267.179 57.3772C263.671 57.3772 260.463 56.4841 257.551 54.687C254.639 52.8953 252.33 50.3881 250.623 47.1765C248.922 43.9593 248.068 40.315 248.068 36.238C248.068 32.161 248.922 28.5556 250.623 25.4105H250.629ZM279.736 29.0936C278.576 27.0302 277.058 25.4604 275.178 24.3788C273.299 23.2971 271.279 22.7591 269.104 22.7591C266.928 22.7591 264.909 23.286 263.029 24.3399C261.149 25.3938 259.632 26.9414 258.472 28.9827C257.311 31.0184 256.731 33.4368 256.731 36.238C256.731 39.0392 257.311 41.4909 258.472 43.6043C259.632 45.7176 261.16 47.3262 263.063 48.4301C264.965 49.5339 266.973 50.0886 269.098 50.0886C271.224 50.0886 273.293 49.5505 275.173 48.4689C277.047 47.3873 278.564 45.8064 279.73 43.7208C280.891 41.6351 281.471 39.189 281.471 36.3933C281.471 33.5977 280.891 31.1626 279.73 29.0992L279.736 29.0936Z" fill="#326DE3"/>
			<path d="M91.46 93.3541V84.1907H92.9717V93.3541H91.46ZM92.7206 85.4997V84.1962H96.7314V85.4997H92.7206ZM92.7206 89.4047V88.1012H96.5473V89.4047H92.7206Z" fill="#F87060"/>
			<path d="M100.028 93.5317C99.5314 93.5317 99.0963 93.4485 98.7281 93.2766C98.36 93.1046 98.0699 92.855 97.8691 92.5222C97.6683 92.1894 97.5679 91.7956 97.5679 91.3352C97.5679 90.8471 97.685 90.4477 97.9137 90.1315C98.1424 89.8153 98.4771 89.5768 98.9066 89.4159C99.3362 89.2551 99.8494 89.1774 100.446 89.1774H102.148V90.1426H100.407C99.9609 90.1426 99.6206 90.248 99.3864 90.4643C99.1521 90.6806 99.0349 90.958 99.0349 91.2963C99.0349 91.6347 99.1521 91.9121 99.3864 92.1173C99.6206 92.3225 99.9609 92.4224 100.407 92.4224C100.669 92.4224 100.915 92.3724 101.149 92.2781C101.383 92.1838 101.579 92.023 101.735 91.7956C101.891 91.5681 101.975 91.252 101.991 90.8415L102.371 91.3296C102.326 91.8067 102.215 92.206 102.025 92.5278C101.835 92.8495 101.573 93.0991 101.244 93.2655C100.909 93.4374 100.508 93.5207 100.028 93.5207V93.5317ZM102.237 93.3542V91.3352H101.997V89.0499C101.997 88.6338 101.885 88.3121 101.668 88.0958C101.45 87.8795 101.127 87.7685 100.697 87.7685C100.463 87.7685 100.201 87.7685 99.9163 87.7796C99.6318 87.7907 99.3529 87.7963 99.0796 87.8129C98.8062 87.8295 98.5664 87.8406 98.3544 87.8573V86.587C98.5385 86.5704 98.7449 86.5538 98.968 86.5427C99.1911 86.5316 99.4198 86.5205 99.6597 86.5094C99.8996 86.5038 100.128 86.4983 100.346 86.4983C101.038 86.4983 101.606 86.587 102.064 86.7645C102.516 86.942 102.856 87.2194 103.079 87.5966C103.302 87.9793 103.414 88.4841 103.414 89.122V93.3487H102.242L102.237 93.3542Z" fill="#F87060"/>
			<path d="M105.467 93.3543V86.5871H106.638V89.4993H106.499C106.499 88.8281 106.582 88.2678 106.755 87.8075C106.928 87.3471 107.19 86.9976 107.536 86.7535C107.888 86.5095 108.323 86.3875 108.842 86.3875H108.903C109.433 86.3875 109.874 86.5095 110.219 86.7535C110.571 86.9976 110.827 87.3471 111 87.8075C111.173 88.2678 111.257 88.8336 111.257 89.4993H110.855C110.855 88.8281 110.945 88.2678 111.123 87.8075C111.302 87.3471 111.564 86.9976 111.921 86.7535C112.272 86.5095 112.713 86.3875 113.232 86.3875H113.293C113.823 86.3875 114.264 86.5095 114.621 86.7535C114.972 86.9976 115.24 87.3471 115.418 87.8075C115.597 88.2678 115.686 88.8336 115.686 89.4993V93.3543H114.208V89.2885C114.208 88.7948 114.085 88.4121 113.834 88.1347C113.583 87.8574 113.243 87.7187 112.808 87.7187C112.373 87.7187 111.993 87.8629 111.72 88.1514C111.452 88.4398 111.318 88.8392 111.318 89.3495V93.3543H109.829V89.2885C109.829 88.7948 109.706 88.4121 109.461 88.1347C109.215 87.8574 108.875 87.7187 108.44 87.7187C108.005 87.7187 107.62 87.8629 107.347 88.1514C107.073 88.4398 106.934 88.8392 106.934 89.3495V93.3543H105.456H105.467Z" fill="#F87060"/>
			<path d="M117.081 87.7186V86.587H119.552V87.7186H117.081ZM118.559 85.6496C118.258 85.6496 118.029 85.5719 117.884 85.4111C117.739 85.2502 117.661 85.045 117.661 84.7954C117.661 84.5458 117.733 84.3516 117.884 84.1852C118.029 84.0244 118.258 83.9412 118.559 83.9412C118.86 83.9412 119.1 84.0244 119.245 84.1852C119.39 84.3461 119.468 84.5513 119.468 84.7954C119.468 85.0394 119.396 85.2502 119.245 85.4111C119.1 85.5719 118.866 85.6496 118.559 85.6496ZM118.079 93.3542V86.587H119.557V93.3542H118.079Z" fill="#F87060"/>
			<path d="M124.349 96.0002C124.031 96.0002 123.708 95.9891 123.379 95.9614C123.049 95.9392 122.743 95.9003 122.458 95.8615V94.619C122.759 94.6634 123.083 94.6967 123.423 94.7244C123.763 94.7521 124.081 94.7688 124.377 94.7688C124.968 94.7688 125.437 94.6911 125.8 94.5413C126.162 94.3916 126.419 94.1586 126.58 93.8424C126.742 93.5263 126.826 93.1213 126.826 92.6166V91.3131L127.216 90.4089C127.194 91.0635 127.055 91.607 126.804 92.0508C126.558 92.4945 126.229 92.8274 125.822 93.0548C125.415 93.2822 124.946 93.3931 124.427 93.3931C123.959 93.3931 123.529 93.3044 123.139 93.1269C122.748 92.9494 122.419 92.7053 122.14 92.3892C121.861 92.073 121.649 91.7069 121.499 91.2909C121.348 90.8749 121.27 90.4367 121.27 89.9652V89.7266C121.27 89.2496 121.348 88.8059 121.504 88.3954C121.661 87.9849 121.884 87.6299 122.168 87.3248C122.453 87.0198 122.798 86.7812 123.195 86.6148C123.596 86.4484 124.031 86.3652 124.511 86.3652C125.069 86.3652 125.554 86.4817 125.984 86.7258C126.408 86.9643 126.742 87.3138 126.988 87.7741C127.233 88.2345 127.361 88.7948 127.378 89.4549L127.049 89.4937V86.5816H128.226V92.5445C128.226 93.3654 128.092 94.0255 127.83 94.5358C127.568 95.0406 127.149 95.4122 126.58 95.6452C126.012 95.8781 125.27 95.9946 124.355 95.9946L124.349 96.0002ZM124.79 92.0952C125.158 92.0952 125.498 92.012 125.805 91.8511C126.112 91.6902 126.357 91.4517 126.536 91.1467C126.714 90.8416 126.809 90.4755 126.809 90.0484V89.5325C126.809 89.1165 126.714 88.7615 126.525 88.4731C126.335 88.1846 126.09 87.9627 125.788 87.813C125.487 87.6632 125.158 87.5855 124.801 87.5855C124.399 87.5855 124.037 87.6799 123.73 87.8684C123.418 88.057 123.178 88.3177 123.005 88.6616C122.832 89 122.748 89.3938 122.748 89.8487C122.748 90.3035 122.838 90.7084 123.016 91.0468C123.195 91.3852 123.434 91.6459 123.741 91.8234C124.048 92.0009 124.399 92.0952 124.795 92.0952H124.79Z" fill="#F87060"/>
			<path d="M129.498 85.3222V84.1907H131.885V85.3222H129.498ZM130.396 93.3541V84.1907H131.885V93.3541H130.396Z" fill="#F87060"/>
			<path d="M133.386 87.7186V86.587H135.857V87.7186H133.386ZM134.864 85.6496C134.563 85.6496 134.334 85.5719 134.189 85.4111C134.044 85.2502 133.966 85.045 133.966 84.7954C133.966 84.5458 134.038 84.3516 134.189 84.1852C134.334 84.0244 134.563 83.9412 134.864 83.9412C135.165 83.9412 135.405 84.0244 135.55 84.1852C135.695 84.3461 135.773 84.5513 135.773 84.7954C135.773 85.0394 135.701 85.2502 135.55 85.4111C135.405 85.5719 135.176 85.6496 134.864 85.6496ZM134.384 93.3542V86.587H135.862V93.3542H134.384Z" fill="#F87060"/>
			<path d="M141.022 93.5816C140.431 93.5816 139.918 93.4817 139.483 93.282C139.042 93.0823 138.685 92.8161 138.412 92.4833C138.138 92.1505 137.926 91.7788 137.787 91.3683C137.648 90.9523 137.581 90.5308 137.581 90.0926V89.854C137.581 89.4103 137.648 88.9832 137.787 88.5672C137.926 88.1512 138.133 87.7795 138.412 87.4522C138.691 87.1194 139.042 86.8587 139.466 86.6646C139.89 86.4704 140.386 86.3706 140.95 86.3706C141.681 86.3706 142.294 86.5315 142.779 86.8476C143.265 87.1638 143.633 87.5798 143.878 88.0846C144.124 88.5894 144.247 89.1385 144.247 89.7209V90.3089H138.216V89.316H143.254L142.852 89.8208C142.852 89.3659 142.779 88.9776 142.64 88.6448C142.495 88.312 142.289 88.0569 142.01 87.8794C141.731 87.7019 141.385 87.6076 140.961 87.6076C140.537 87.6076 140.158 87.7074 139.862 87.9071C139.566 88.1068 139.349 88.3841 139.204 88.7336C139.059 89.083 138.992 89.499 138.992 89.9761C138.992 90.4254 139.064 90.8303 139.204 91.1853C139.349 91.5403 139.572 91.8232 139.873 92.0284C140.174 92.2337 140.565 92.3335 141.033 92.3335C141.502 92.3335 141.893 92.2337 142.194 92.0284C142.495 91.8232 142.679 91.5847 142.752 91.3073H144.129C144.035 91.7677 143.85 92.1671 143.566 92.511C143.287 92.8549 142.93 93.1156 142.501 93.2987C142.071 93.4817 141.586 93.576 141.039 93.576L141.022 93.5816Z" fill="#F87060"/>
			<path d="M151.587 93.5817C151.007 93.5817 150.5 93.4819 150.064 93.2877C149.629 93.0936 149.272 92.8218 148.993 92.4835C148.709 92.1451 148.503 91.7679 148.363 91.3519C148.224 90.9359 148.157 90.5199 148.157 90.0927V89.8653C148.157 89.4216 148.229 88.9945 148.369 88.5784C148.514 88.1624 148.726 87.7908 149.01 87.458C149.295 87.1252 149.652 86.8589 150.076 86.6592C150.5 86.4595 151.002 86.3652 151.571 86.3652C152.14 86.3652 152.67 86.4762 153.127 86.6925C153.584 86.9088 153.952 87.2195 154.231 87.6133C154.51 88.0127 154.666 88.4731 154.7 89H153.272C153.222 88.6228 153.049 88.3066 152.759 88.0459C152.469 87.7852 152.073 87.6577 151.565 87.6577C151.124 87.6577 150.767 87.7575 150.477 87.9572C150.193 88.1569 149.975 88.4342 149.836 88.7781C149.691 89.1276 149.624 89.527 149.624 89.9763C149.624 90.4256 149.691 90.8027 149.83 91.1577C149.97 91.5072 150.182 91.7845 150.472 91.9842C150.762 92.1839 151.13 92.2838 151.576 92.2838C151.922 92.2838 152.218 92.2227 152.463 92.1007C152.709 91.9787 152.909 91.8178 153.066 91.6126C153.216 91.4074 153.305 91.1744 153.328 90.9026H154.767C154.745 91.4462 154.588 91.9177 154.298 92.317C154.014 92.7164 153.635 93.0215 153.16 93.2434C152.686 93.4653 152.162 93.5762 151.571 93.5762L151.587 93.5817Z" fill="#F87060"/>
			<path d="M159.497 93.5816C158.9 93.5816 158.376 93.4817 157.919 93.2876C157.467 93.0934 157.082 92.8272 156.77 92.5055C156.457 92.1782 156.223 91.8066 156.061 91.3961C155.899 90.9801 155.821 90.553 155.821 90.1092V89.8596C155.821 89.4158 155.905 88.9832 156.067 88.5672C156.228 88.1512 156.468 87.774 156.786 87.4412C157.104 87.1083 157.489 86.8476 157.941 86.659C158.393 86.4649 158.912 86.3706 159.492 86.3706C160.072 86.3706 160.585 86.4649 161.037 86.659C161.489 86.8532 161.874 87.1139 162.192 87.4412C162.51 87.7684 162.755 88.1456 162.917 88.5672C163.079 88.9887 163.162 89.4158 163.162 89.8596V90.1092C163.162 90.553 163.084 90.9801 162.922 91.3961C162.761 91.8121 162.526 92.1782 162.214 92.5055C161.902 92.8327 161.522 93.0934 161.065 93.2876C160.613 93.4817 160.083 93.5816 159.486 93.5816H159.497ZM159.497 92.3002C159.96 92.3002 160.356 92.2004 160.685 91.9951C161.015 91.7899 161.266 91.5126 161.439 91.1631C161.611 90.8137 161.695 90.4198 161.695 89.9816C161.695 89.5434 161.606 89.1385 161.433 88.789C161.254 88.4396 161.003 88.1623 160.674 87.957C160.345 87.7518 159.955 87.6519 159.503 87.6519C159.051 87.6519 158.655 87.7573 158.331 87.957C158.002 88.1623 157.751 88.4396 157.573 88.789C157.394 89.1385 157.311 89.5379 157.311 89.9816C157.311 90.4254 157.394 90.8081 157.567 91.1631C157.74 91.5126 157.991 91.7899 158.32 91.9951C158.649 92.2004 159.046 92.3002 159.508 92.3002H159.497Z" fill="#F87060"/>
			<path d="M164.043 85.3222V84.1907H166.431V85.3222H164.043ZM164.942 93.3541V84.1907H166.431V93.3541H164.942Z" fill="#F87060"/>
			<path d="M167.775 85.3222V84.1907H170.163V85.3222H167.775ZM168.673 93.3541V84.1907H170.163V93.3541H168.673Z" fill="#F87060"/>
			<path d="M175.301 93.5816C174.709 93.5816 174.196 93.4817 173.761 93.282C173.32 93.0823 172.963 92.8161 172.69 92.4833C172.417 92.1505 172.205 91.7788 172.065 91.3683C171.926 90.9523 171.859 90.5308 171.859 90.0926V89.854C171.859 89.4103 171.926 88.9832 172.065 88.5672C172.205 88.1512 172.411 87.7795 172.69 87.4522C172.969 87.1194 173.32 86.8587 173.744 86.6646C174.168 86.4704 174.665 86.3706 175.228 86.3706C175.959 86.3706 176.572 86.5315 177.058 86.8476C177.543 87.1638 177.911 87.5798 178.157 88.0846C178.402 88.5894 178.525 89.1385 178.525 89.7209V90.3089H172.495V89.316H177.532L177.13 89.8208C177.13 89.3659 177.058 88.9776 176.918 88.6448C176.773 88.312 176.567 88.0569 176.288 87.8794C176.009 87.7019 175.663 87.6076 175.239 87.6076C174.815 87.6076 174.436 87.7074 174.14 87.9071C173.845 88.1068 173.627 88.3841 173.482 88.7336C173.337 89.083 173.27 89.499 173.27 89.9761C173.27 90.4254 173.343 90.8303 173.482 91.1853C173.627 91.5403 173.85 91.8232 174.152 92.0284C174.453 92.2337 174.843 92.3335 175.312 92.3335C175.78 92.3335 176.171 92.2337 176.472 92.0284C176.773 91.8232 176.957 91.5847 177.03 91.3073H178.408C178.313 91.7677 178.129 92.1671 177.844 92.511C177.565 92.8549 177.208 93.1156 176.779 93.2987C176.349 93.4817 175.864 93.576 175.317 93.576L175.301 93.5816Z" fill="#F87060"/>
			<path d="M182.708 96.0002C182.39 96.0002 182.067 95.9891 181.738 95.9614C181.409 95.9392 181.102 95.9003 180.817 95.8615V94.619C181.118 94.6634 181.442 94.6967 181.782 94.7244C182.123 94.7521 182.441 94.7688 182.736 94.7688C183.322 94.7688 183.796 94.6911 184.153 94.5413C184.51 94.3916 184.772 94.1586 184.934 93.8424C185.096 93.5263 185.179 93.1213 185.179 92.6166V91.3131L185.57 90.4089C185.548 91.0635 185.408 91.607 185.157 92.0508C184.912 92.4945 184.583 92.8274 184.175 93.0548C183.768 93.2822 183.305 93.3931 182.781 93.3931C182.312 93.3931 181.883 93.3044 181.492 93.1269C181.107 92.9494 180.773 92.7053 180.494 92.3892C180.215 92.073 180.003 91.7069 179.852 91.2909C179.702 90.8749 179.624 90.4367 179.624 89.9652V89.7266C179.624 89.2496 179.702 88.8059 179.858 88.3954C180.014 87.9849 180.232 87.6299 180.522 87.3248C180.812 87.0198 181.152 86.7812 181.548 86.6148C181.944 86.4484 182.385 86.3652 182.864 86.3652C183.422 86.3652 183.908 86.4817 184.337 86.7258C184.761 86.9643 185.096 87.3138 185.341 87.7741C185.587 88.2345 185.715 88.7948 185.732 89.4549L185.403 89.4937V86.5816H186.574V92.5445C186.574 93.3654 186.44 94.0255 186.178 94.5358C185.916 95.0406 185.497 95.4122 184.928 95.6452C184.359 95.8781 183.618 95.9946 182.703 95.9946L182.708 96.0002ZM183.149 92.0952C183.517 92.0952 183.857 92.012 184.164 91.8511C184.471 91.6902 184.716 91.4517 184.895 91.1467C185.073 90.8416 185.168 90.4755 185.168 90.0484V89.5325C185.168 89.1165 185.073 88.7615 184.884 88.4731C184.694 88.1846 184.449 87.9627 184.147 87.813C183.846 87.6632 183.517 87.5855 183.166 87.5855C182.764 87.5855 182.401 87.6799 182.095 87.8684C181.782 88.057 181.542 88.3177 181.37 88.6616C181.197 89 181.113 89.3938 181.113 89.8487C181.113 90.3035 181.202 90.7084 181.375 91.0468C181.554 91.3852 181.793 91.6459 182.1 91.8234C182.407 92.0009 182.758 92.0952 183.155 92.0952H183.149Z" fill="#F87060"/>
			<path d="M190.574 93.5317C190.077 93.5317 189.642 93.4485 189.274 93.2766C188.906 93.1046 188.616 92.855 188.415 92.5222C188.214 92.1894 188.114 91.7956 188.114 91.3352C188.114 90.8471 188.231 90.4477 188.46 90.1315C188.688 89.8153 189.023 89.5768 189.453 89.4159C189.882 89.2551 190.395 89.1774 190.992 89.1774H192.693V90.1426H190.953C190.507 90.1426 190.167 90.248 189.932 90.4643C189.698 90.6806 189.581 90.958 189.581 91.2963C189.581 91.6347 189.698 91.9121 189.932 92.1173C190.167 92.3225 190.507 92.4224 190.953 92.4224C191.215 92.4224 191.461 92.3724 191.695 92.2781C191.929 92.1838 192.124 92.023 192.281 91.7956C192.437 91.5681 192.521 91.252 192.537 90.8415L192.917 91.3296C192.872 91.8067 192.76 92.206 192.571 92.5278C192.381 92.8495 192.119 93.0991 191.79 93.2655C191.455 93.4374 191.053 93.5207 190.574 93.5207V93.5317ZM192.783 93.3542V91.3352H192.543V89.0499C192.543 88.6338 192.431 88.3121 192.214 88.0958C191.996 87.8795 191.673 87.7685 191.243 87.7685C191.009 87.7685 190.747 87.7685 190.462 87.7796C190.178 87.7907 189.899 87.7963 189.625 87.8129C189.352 87.824 189.112 87.8406 188.9 87.8573V86.587C189.084 86.5704 189.291 86.5538 189.514 86.5427C189.737 86.5316 189.966 86.5205 190.206 86.5094C190.445 86.5038 190.674 86.4983 190.892 86.4983C191.583 86.4983 192.152 86.587 192.61 86.7645C193.062 86.942 193.402 87.2194 193.625 87.5966C193.848 87.9793 193.96 88.4841 193.96 89.122V93.3487H192.788L192.783 93.3542Z" fill="#F87060"/>
			<path d="M195.075 87.6798V86.5871H199.822V87.6798H195.075ZM198.796 93.4264C198.232 93.4264 197.758 93.3487 197.368 93.199C196.983 93.0492 196.687 92.7885 196.492 92.4224C196.297 92.0563 196.196 91.5571 196.196 90.9303V84.6179H197.597V91.0579C197.597 91.4073 197.691 91.6791 197.881 91.8677C198.071 92.0563 198.338 92.1506 198.684 92.1506H199.822V93.4319H198.801L198.796 93.4264Z" fill="#F87060"/>
			<path d="M204.352 93.5816C203.761 93.5816 203.247 93.4817 202.812 93.282C202.372 93.0823 202.015 92.8161 201.741 92.4833C201.468 92.1505 201.256 91.7788 201.117 91.3683C200.977 90.9523 200.91 90.5308 200.91 90.0926V89.854C200.91 89.4103 200.977 88.9832 201.117 88.5672C201.256 88.1512 201.462 87.7795 201.741 87.4522C202.02 87.1194 202.372 86.8587 202.796 86.6646C203.22 86.4704 203.716 86.3706 204.279 86.3706C205.01 86.3706 205.624 86.5315 206.109 86.8476C206.594 87.1638 206.963 87.5798 207.208 88.0846C207.453 88.5894 207.576 89.1385 207.576 89.7209V90.3089H201.546V89.316H206.583L206.182 89.8208C206.182 89.3659 206.109 88.9776 205.97 88.6448C205.825 88.312 205.618 88.0569 205.339 87.8794C205.06 87.7019 204.714 87.6076 204.291 87.6076C203.867 87.6076 203.487 87.7074 203.192 87.9071C202.896 88.1068 202.678 88.3841 202.533 88.7336C202.388 89.083 202.321 89.499 202.321 89.9761C202.321 90.4254 202.394 90.8303 202.533 91.1853C202.678 91.5403 202.902 91.8232 203.203 92.0284C203.504 92.2337 203.894 92.3335 204.363 92.3335C204.832 92.3335 205.222 92.2337 205.523 92.0284C205.825 91.8232 206.009 91.5847 206.081 91.3073H207.459C207.364 91.7677 207.18 92.1671 206.896 92.511C206.617 92.8549 206.26 93.1156 205.83 93.2987C205.401 93.4817 204.915 93.576 204.369 93.576L204.352 93.5816Z" fill="#F87060"/>
			<path d="M213.857 93.5317C213.361 93.5317 212.925 93.4485 212.557 93.2766C212.189 93.1046 211.899 92.855 211.698 92.5222C211.497 92.1894 211.397 91.7956 211.397 91.3352C211.397 90.8471 211.514 90.4477 211.743 90.1315C211.972 89.8153 212.306 89.5768 212.736 89.4159C213.165 89.2551 213.678 89.1774 214.275 89.1774H215.977V90.1426H214.236C213.79 90.1426 213.45 90.248 213.215 90.4643C212.981 90.6806 212.864 90.958 212.864 91.2963C212.864 91.6347 212.981 91.9121 213.215 92.1173C213.45 92.3225 213.79 92.4224 214.236 92.4224C214.498 92.4224 214.744 92.3724 214.978 92.2781C215.212 92.1838 215.408 92.023 215.564 91.7956C215.72 91.5681 215.804 91.252 215.82 90.8415L216.2 91.3296C216.155 91.8067 216.044 92.206 215.854 92.5278C215.664 92.8495 215.402 93.0991 215.073 93.2655C214.738 93.4374 214.337 93.5207 213.857 93.5207V93.5317ZM216.066 93.3542V91.3352H215.826V89.0499C215.826 88.6338 215.715 88.3121 215.497 88.0958C215.279 87.8795 214.956 87.7685 214.526 87.7685C214.292 87.7685 214.03 87.7685 213.745 87.7796C213.461 87.7907 213.182 87.7963 212.909 87.8129C212.635 87.824 212.395 87.8406 212.184 87.8573V86.587C212.368 86.5704 212.574 86.5538 212.797 86.5427C213.02 86.5316 213.249 86.5205 213.489 86.5094C213.729 86.5038 213.957 86.4983 214.175 86.4983C214.867 86.4983 215.436 86.587 215.893 86.7645C216.345 86.942 216.685 87.2194 216.908 87.5966C217.131 87.9793 217.243 88.4841 217.243 89.122V93.3487H216.072L216.066 93.3542Z" fill="#F87060"/>
			<path d="M218.476 85.3222V84.1907H220.863V85.3222H218.476ZM219.374 93.3541V84.1907H220.863V93.3541H219.374Z" fill="#F87060"/>
			<path d="M222.213 85.3222V84.1907H224.601V85.3222H222.213ZM223.111 93.3541V84.1907H224.601V93.3541H223.111Z" fill="#F87060"/>
			<path d="M226.252 88.1568V87.3414H226.453C226.715 87.3414 226.921 87.297 227.072 87.2138C227.223 87.1306 227.334 86.992 227.407 86.8034C227.479 86.6148 227.513 86.3707 227.513 86.0712V85.1448L227.842 85.7827C227.78 85.816 227.702 85.8437 227.602 85.8659C227.501 85.8881 227.395 85.8992 227.284 85.8992C227.033 85.8992 226.821 85.8216 226.648 85.6662C226.475 85.5109 226.391 85.2946 226.391 85.0062C226.391 84.7399 226.475 84.518 226.637 84.3405C226.799 84.163 227.027 84.0798 227.312 84.0798C227.496 84.0798 227.669 84.1242 227.831 84.213C227.992 84.3017 228.121 84.4515 228.227 84.6623C228.333 84.873 228.383 85.1726 228.383 85.5609V85.7994C228.383 86.4095 228.305 86.8866 228.143 87.2305C227.981 87.5744 227.758 87.8129 227.462 87.9516C227.167 88.0902 226.815 88.1568 226.403 88.1568H226.252Z" fill="#F87060"/>
			<path d="M231.551 93.5317C231.055 93.5317 230.62 93.4485 230.252 93.2766C229.883 93.1046 229.593 92.855 229.393 92.5222C229.192 92.1894 229.091 91.7956 229.091 91.3352C229.091 90.8471 229.208 90.4477 229.437 90.1315C229.666 89.8153 230.001 89.5768 230.43 89.4159C230.86 89.2551 231.373 89.1774 231.97 89.1774H233.671V90.1426H231.931C231.484 90.1426 231.144 90.248 230.91 90.4643C230.676 90.6806 230.558 90.958 230.558 91.2963C230.558 91.6347 230.676 91.9121 230.91 92.1173C231.144 92.3225 231.484 92.4224 231.931 92.4224C232.193 92.4224 232.438 92.3724 232.673 92.2781C232.907 92.1838 233.102 92.023 233.258 91.7956C233.414 91.5681 233.498 91.252 233.515 90.8415L233.894 91.3296C233.85 91.8067 233.738 92.206 233.548 92.5278C233.359 92.8495 233.096 93.0991 232.767 93.2655C232.433 93.4374 232.031 93.5207 231.551 93.5207V93.5317ZM233.76 93.3542V91.3352H233.52V89.0499C233.52 88.6338 233.409 88.3121 233.191 88.0958C232.974 87.8795 232.65 87.7685 232.221 87.7685C231.986 87.7685 231.724 87.7685 231.44 87.7796C231.155 87.7907 230.876 87.7963 230.603 87.8129C230.33 87.824 230.09 87.8406 229.878 87.8573V86.587C230.062 86.5704 230.268 86.5538 230.491 86.5427C230.715 86.5316 230.943 86.5205 231.183 86.5094C231.423 86.5038 231.652 86.4983 231.869 86.4983C232.561 86.4983 233.13 86.587 233.587 86.7645C234.039 86.942 234.379 87.2194 234.603 87.5966C234.826 87.9793 234.937 88.4841 234.937 89.122V93.3487H233.766L233.76 93.3542Z" fill="#F87060"/>
			<path d="M236.99 93.3543V86.5871H238.161V89.4993H238.022C238.022 88.8281 238.106 88.2678 238.278 87.8075C238.451 87.3471 238.714 86.9976 239.059 86.7535C239.405 86.5095 239.846 86.3875 240.365 86.3875H240.426C240.956 86.3875 241.397 86.5095 241.748 86.7535C242.094 86.9976 242.356 87.3471 242.529 87.8075C242.702 88.2678 242.786 88.8336 242.786 89.4993H242.384C242.384 88.8281 242.473 88.2678 242.646 87.8075C242.825 87.3471 243.087 86.9976 243.444 86.7535C243.795 86.5095 244.236 86.3875 244.755 86.3875H244.816C245.346 86.3875 245.787 86.5095 246.144 86.7535C246.495 86.9976 246.763 87.3471 246.936 87.8075C247.114 88.2678 247.204 88.8336 247.204 89.4993V93.3543H245.725V89.2885C245.725 88.7948 245.603 88.4121 245.352 88.1347C245.101 87.8574 244.76 87.7187 244.325 87.7187C243.89 87.7187 243.511 87.8629 243.237 88.1514C242.97 88.4398 242.836 88.8392 242.836 89.3495V93.3543H241.346V89.2885C241.346 88.7948 241.224 88.4121 240.978 88.1347C240.733 87.8574 240.393 87.7187 239.957 87.7187C239.522 87.7187 239.137 87.8629 238.864 88.1514C238.591 88.4398 238.451 88.8392 238.451 89.3495V93.3543H236.973H236.99Z" fill="#F87060"/>
			<path d="M253.055 93.5816C252.497 93.5816 252.006 93.4595 251.571 93.2155C251.136 92.9714 250.796 92.6219 250.551 92.1615C250.3 91.7012 250.166 91.1465 250.138 90.4919H250.439V93.3541H249.268V84.1907H250.746V88.7114L250.305 89.5268C250.333 88.8057 250.472 88.2177 250.729 87.7518C250.986 87.2858 251.326 86.9419 251.744 86.7145C252.163 86.4871 252.637 86.3761 253.156 86.3761C253.635 86.3761 254.07 86.4649 254.461 86.6424C254.851 86.8199 255.186 87.064 255.471 87.3746C255.749 87.6908 255.967 88.0569 256.112 88.4729C256.257 88.8889 256.335 89.3437 256.335 89.8263V90.0759C256.335 90.564 256.257 91.0133 256.106 91.4294C255.956 91.8454 255.733 92.217 255.443 92.5387C255.153 92.8605 254.807 93.1156 254.4 93.2987C253.998 93.4817 253.546 93.576 253.05 93.576L253.055 93.5816ZM252.787 92.3113C253.2 92.3113 253.563 92.2115 253.875 92.0118C254.188 91.8121 254.427 91.5348 254.6 91.1798C254.773 90.8248 254.857 90.4143 254.857 89.9539C254.857 89.4935 254.768 89.0775 254.595 88.728C254.416 88.3841 254.176 88.1123 253.87 87.9182C253.563 87.724 253.2 87.6297 252.793 87.6297C252.425 87.6297 252.079 87.7129 251.761 87.8794C251.443 88.0458 251.186 88.2898 250.991 88.6004C250.796 88.9166 250.701 89.2938 250.701 89.7376V90.2756C250.701 90.7027 250.802 91.0688 250.997 91.3739C251.192 91.679 251.454 91.9119 251.772 92.0728C252.09 92.2337 252.43 92.3113 252.793 92.3113H252.787Z" fill="#F87060"/>
			<path d="M257.361 87.7186V86.587H259.832V87.7186H257.361ZM258.84 85.6496C258.538 85.6496 258.31 85.5719 258.165 85.4111C258.02 85.2502 257.941 85.045 257.941 84.7954C257.941 84.5458 258.014 84.3516 258.165 84.1852C258.31 84.0244 258.538 83.9412 258.84 83.9412C259.141 83.9412 259.381 84.0244 259.526 84.1852C259.671 84.3461 259.749 84.5513 259.749 84.7954C259.749 85.0394 259.676 85.2502 259.526 85.4111C259.381 85.5719 259.152 85.6496 258.84 85.6496ZM258.36 93.3542V86.587H259.838V93.3542H258.36Z" fill="#F87060"/>
			<path d="M264.998 93.5816C264.407 93.5816 263.893 93.4817 263.458 93.282C263.018 93.0823 262.661 92.8161 262.387 92.4833C262.114 92.1505 261.902 91.7788 261.763 91.3683C261.623 90.9523 261.556 90.5308 261.556 90.0926V89.854C261.556 89.4103 261.623 88.9832 261.763 88.5672C261.902 88.1512 262.108 87.7795 262.387 87.4522C262.666 87.1194 263.018 86.8587 263.442 86.6646C263.866 86.4704 264.362 86.3706 264.925 86.3706C265.656 86.3706 266.27 86.5315 266.755 86.8476C267.24 87.1638 267.609 87.5798 267.854 88.0846C268.099 88.5894 268.222 89.1385 268.222 89.7209V90.3089H262.192V89.316H267.229L266.828 89.8208C266.828 89.3659 266.755 88.9776 266.616 88.6448C266.471 88.312 266.264 88.0569 265.985 87.8794C265.706 87.7019 265.36 87.6076 264.937 87.6076C264.513 87.6076 264.133 87.7074 263.838 87.9071C263.542 88.1068 263.324 88.3841 263.179 88.7336C263.034 89.083 262.967 89.499 262.967 89.9761C262.967 90.4254 263.04 90.8303 263.179 91.1853C263.324 91.5403 263.548 91.8232 263.849 92.0284C264.15 92.2337 264.54 92.3335 265.009 92.3335C265.478 92.3335 265.868 92.2337 266.169 92.0284C266.471 91.8232 266.655 91.5847 266.727 91.3073H268.105C268.01 91.7677 267.826 92.1671 267.542 92.511C267.263 92.8549 266.906 93.1156 266.476 93.2987C266.047 93.4817 265.561 93.576 265.015 93.576L264.998 93.5816Z" fill="#F87060"/>
			<path d="M269.807 93.3542V86.587H270.978V89.488H270.839C270.839 88.8168 270.928 88.2566 271.112 87.7962C271.29 87.3358 271.569 86.9863 271.938 86.7478C272.306 86.5093 272.769 86.3928 273.315 86.3928H273.377C274.219 86.3928 274.844 86.6535 275.257 87.1805C275.669 87.7074 275.876 88.4785 275.876 89.488V93.3542H274.398V89.2883C274.398 88.8224 274.264 88.4396 273.996 88.1512C273.728 87.8627 273.354 87.7185 272.886 87.7185C272.417 87.7185 272.021 87.8683 271.726 88.1623C271.43 88.4563 271.285 88.8556 271.285 89.3493V93.3542H269.807Z" fill="#F87060"/>
			<path d="M276.997 87.6798V86.5871H281.744V87.6798H276.997ZM280.717 93.4264C280.154 93.4264 279.68 93.3487 279.289 93.199C278.904 93.0492 278.609 92.7885 278.413 92.4224C278.218 92.0563 278.118 91.5571 278.118 90.9303V84.6179H279.518V91.0579C279.518 91.4073 279.613 91.6791 279.802 91.8677C279.992 92.0563 280.26 92.1506 280.606 92.1506H281.744V93.4319H280.723L280.717 93.4264Z" fill="#F87060"/>
			<path d="M286.273 93.5816C285.682 93.5816 285.169 93.4817 284.734 93.282C284.293 93.0823 283.936 92.8161 283.663 92.4833C283.389 92.1505 283.177 91.7788 283.038 91.3683C282.898 90.9523 282.832 90.5308 282.832 90.0926V89.854C282.832 89.4103 282.898 88.9832 283.038 88.5672C283.177 88.1512 283.384 87.7795 283.663 87.4522C283.942 87.1194 284.293 86.8587 284.717 86.6646C285.141 86.4704 285.637 86.3706 286.201 86.3706C286.932 86.3706 287.545 86.5315 288.03 86.8476C288.516 87.1638 288.884 87.5798 289.129 88.0846C289.375 88.5894 289.497 89.1385 289.497 89.7209V90.3089H283.467V89.316H288.505L288.103 89.8208C288.103 89.3659 288.03 88.9776 287.891 88.6448C287.746 88.312 287.54 88.0569 287.261 87.8794C286.982 87.7019 286.636 87.6076 286.212 87.6076C285.788 87.6076 285.409 87.7074 285.113 87.9071C284.817 88.1068 284.6 88.3841 284.455 88.7336C284.31 89.083 284.243 89.499 284.243 89.9761C284.243 90.4254 284.315 90.8303 284.455 91.1853C284.6 91.5403 284.823 91.8232 285.124 92.0284C285.425 92.2337 285.816 92.3335 286.284 92.3335C286.753 92.3335 287.143 92.2337 287.445 92.0284C287.746 91.8232 287.93 91.5847 288.003 91.3073H289.38C289.286 91.7677 289.101 92.1671 288.817 92.511C288.538 92.8549 288.181 93.1156 287.752 93.2987C287.322 93.4817 286.837 93.576 286.29 93.576L286.273 93.5816Z" fill="#F87060"/>
			<path d="M26.6023 33.0541C30.8846 33.0541 34.3561 29.6022 34.3561 25.344C34.3561 21.0858 30.8846 17.6338 26.6023 17.6338C22.3201 17.6338 18.8486 21.0858 18.8486 25.344C18.8486 29.6022 22.3201 33.0541 26.6023 33.0541Z" fill="#F87060"/>
			<path d="M43.7052 40.6255C46.9461 40.6255 49.5735 38.0129 49.5735 34.7902C49.5735 31.5674 46.9461 28.9548 43.7052 28.9548C40.4642 28.9548 37.8369 31.5674 37.8369 34.7902C37.8369 38.0129 40.4642 40.6255 43.7052 40.6255Z" fill="#F87060"/>
			<path d="M13.6496 5.68567C13.6496 3.51368 11.879 1.75293 9.6947 1.75293C7.51044 1.75293 5.73975 3.51368 5.73975 5.68567V38.1793C5.73975 40.3513 7.51044 42.1121 9.6947 42.1121C11.879 42.1121 13.6496 40.3513 13.6496 38.1793V5.68567Z" fill="#326DE3"/>
			<path d="M62.2917 5.68567C62.2917 3.51368 60.521 1.75293 58.3368 1.75293C56.1525 1.75293 54.3818 3.51368 54.3818 5.68567V42.5336C54.3818 44.7056 56.1525 46.4664 58.3368 46.4664C60.521 46.4664 62.2917 44.7056 62.2917 42.5336V5.68567Z" fill="#326DE3"/>
			<path d="M66.9553 39.078V49.0181C66.9553 67.406 51.9666 82.3105 33.4748 82.3105C14.9831 82.3105 0 67.406 0 49.0236V31.562H3.75414C19.9979 31.562 33.5418 43.0718 36.5931 58.3368C41.9202 46.9657 53.5118 39.0836 66.9553 39.0836V39.078Z" fill="#F87060"/>
			<path d="M33.4806 96.0002C32.1753 96.0002 31.121 94.9518 31.121 93.6538V93.4264C31.121 88.5673 27.9972 84.1354 23.3394 82.4048C16.573 79.8865 10.7884 75.4434 6.60476 69.5471C2.32628 63.5231 0.0615234 56.4231 0.0615234 49.0236C0.0615234 47.7256 1.11581 46.6772 2.42111 46.6772C3.72641 46.6772 4.78069 47.7256 4.78069 49.0236C4.78069 61.8646 12.9026 73.5131 24.9905 78.0116C28.1813 79.1987 30.8923 81.2787 32.8391 84.03C34.797 86.8034 35.8346 90.0539 35.8346 93.432V93.6594C35.8346 94.9574 34.7803 96.0057 33.475 96.0057L33.4806 96.0002Z" fill="#F87060"/>
		</g>
		<defs>
			<clipPath id="clip0_1025_30561">
				<rect width="290" height="96" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>