import { defineStore } from "pinia";
import { getCookie, setCookie } from "@/libraries/utils.js"
import { data } from "@/config/data.js"
import { router } from "@/router.js";
import { DEFAULT_PATH } from "@/libraries/network.js";
import {useMainStateStore} from '@/stores/mainState.js';

const COOKIE_EXPIRATION_ONE_WEEK = 7*24

const FAKE_NOW_OFFSET = " 00:00:11"

const computeFakeNow = () => {
    let queryParamDt = ""+ (new URLSearchParams(location.search).get('dt'))
    queryParamDt = queryParamDt!="null" ? queryParamDt.replaceAll('"', '') : ""
    queryParamDt = queryParamDt ? (queryParamDt.includes(":") ? queryParamDt : (queryParamDt + FAKE_NOW_OFFSET)) : ""
    // console.debug("fakeNow: '", queryParamDt, "'")
    return queryParamDt
}

export const useNavigationStore = defineStore("navigation", {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			fakeNow: computeFakeNow(),
			currentPage: "",
			nextPage: ""
		}
	},
	/*VARIABILI CALCOLATE*/
	getters: {},
	/*FUNZIONI*/
	actions: {
		setCurrentPage(pageName){
			console.debug("[navigationStore/setCurrentPage] pageName = ", pageName)
			this.currentPage = pageName;
			setCookie('lastVisitedPage', pageName, COOKIE_EXPIRATION_ONE_WEEK)
		},
		setNextPage(pageName){
			console.debug("[navigationStore/setNextPage] pageName = ", pageName)
			this.nextPage = pageName;
		},
		async goHome(){
			await this.goToPage('homeModules')
		},
		async goToPage(pageName){
			const to = {
				name: pageName,
			}
			if(this.fakeNow) {
				let strippedOffset = this.fakeNow.replace(FAKE_NOW_OFFSET, "")
				to.query = { dt: strippedOffset }
			}
			console.debug("[navigationStore/goToPage] pageName = ", to.name, to.query)

			try {
				await router.push(to) // https://router.vuejs.org/guide/essentials/navigation.html#navigate-to-a-different-location
				this.currentPage = pageName
			}
			catch(e) {
				console.error(pageName, e)
			}
		},
		async goToNextPage(){
			console.debug("[navigationStore/goToNextPage] this.nextPage = ", this.nextPage)
			await this.goToPage(this.nextPage)
		},
		url(path = '/'){
			return path + (this.fakeNow ? '?dt='+this.fakeNow : '')
		},
		getCurrentFullPath(){
			let fullPath = DEFAULT_PATH+"/"+this.currentPage
			console.debug("[navigationStore/getCurrentFullPath] fullPath = ", fullPath)
			return fullPath
		},
		async setStartingPath(){
			// if the starting path is not /app, set that path and set the cookie; if not, and if a cookie is present, load that cookie, else set the initial page and set the cookie
			let subPath = String(window.location).split(DEFAULT_PATH)[1] ?? ""
			if (subPath.length>0 && subPath[0]=="/") {
				let newSubPath = subPath.substring(1)
				subPath = newSubPath
			}
			if (subPath!=""){
				// starting path is not /app (user clicked a specific link, bring it here!)
				console.debug("[navigationStore/setStartingPath] subPath = ", subPath)
				this.setCurrentPage(subPath)
				setCookie('lastVisitedPage', subPath, COOKIE_EXPIRATION_ONE_WEEK)
			} else {
				// starting path is /app, check the cookie (user clicked the landing link, bring it to the latest page they visited)
				let maybeCookie = getCookie('lastVisitedPage') ?? ""
				if(maybeCookie[0]=="/"){ maybeCookie = maybeCookie.substring(1) }
				console.debug("[navigationStore/setStartingPath] maybeCookie = ", maybeCookie)
				if(maybeCookie!=""){
					// cookie detected, bringing user to that page
					this.setCurrentPage(maybeCookie)
					console.debug("[navigationStore/setStartingPath] COOKIE DETECTED, bringing user to:", this.currentPage )
				} else {
					// first access
					this.setCurrentPage("/")
				}
				await this.goToPage(this.currentPage)
			}
		},
		calcNextPage() {

		},
		calcPrevPage() {

		}
	}
});
