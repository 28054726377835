import {QuestionObject} from '@/dataObjects/QuestionObject.js';
import {reactive, ref, watch} from 'vue';
import {getNextId, getPrevId} from '@/libraries/navUtils.js';
import {saveRemoteAnswers} from '@/libraries/network.js';
import {usePage} from '@inertiajs/vue3';
import {useMainStateStore} from '@/stores/mainState.js';

export class PageObject {
	static STATE_NOT_SEEN = 'not_seen'
	static STATE_NOT_COMPLETED = 'not_completed'
	static STATE_COMPLETED = 'completed'

	constructor(pageData, parent, props = {}) {
		//le pagine iniziano da 1, non da 0
		this.parent = parent
		this.index = props.index + 1
		this.isFirst = props.isFirst
		this.isLast = props.isLast
		this.treatmentOnly = pageData.treatmentOnly

		this.id = `${this.parent.id}_page${this.index}`
		this.title = pageData.title
		this.if = pageData.if
		this.questionSubtext1 = pageData.questionSubtext1
		this.questionSubtext2 = pageData.questionSubtext2
		this.questionSubtext3 = pageData.questionSubtext3
		this.questionSubtext4 = pageData.questionSubtext4
		this.questionSubtext5 = pageData.questionSubtext5
		this.questionSubtext6 = pageData.questionSubtext6
		this.questionSubtext7 = pageData.questionSubtext7
		this.state = ref(PageObject.STATE_NOT_SEEN)
		this.mainStore = useMainStateStore()
		/**
		 * @type {QuestionObject[]}
		 */
		this.questions = pageData.questions.map((question,index) => {
			return reactive(new QuestionObject(question, this, {
				index: index,
				isFirst: index === 0,
				isLast: index === pageData.questions.length - 1,
			}))
		})

		/**
		 * la pagina non ha domande: completata
		 * la pagina ha una domanda non vista: non vista
		 * la pagina ha le domande viste, ma ne ha una non risposta: non completata
		 * la pagina ha tutte le domande viste e risposte: completata
		 *
		 * @returns {string}
		 */
		const getState = () => {
			if(!this.questions.length) {
				return PageObject.STATE_COMPLETED
			}

			const notSeen = this.questions.find(question => {
				//considera solo le risposte salvate sul db remoto
				return question.isNotSeen(true)
			})
			if(notSeen) {
				return PageObject.STATE_NOT_SEEN
			}

			const notAnswered = this.questions.find(question => {
				//considera solo le risposte salvate sul db remoto
				return !question.isAnswered(true, true)
			})

			return notAnswered ? PageObject.STATE_NOT_COMPLETED : PageObject.STATE_COMPLETED
		}
		/**
		 * Watch si occupa di aggiornare state nel caso l'array questions cambi,
		 * ovvero nel caso cambi lo stato di una question.
		 * L'array questions non è un reattivo, ma ogni singola question si, quindi
		 * watch lo gestisce comunque bene
		 */
		watch(this.questions, () => {
			this.state.value = getState()
		})
		this.state.value = getState()
		this.navigationStore = parent.navigationStore
	}

	/**
	 * pagina con risposte non viste
	 * @returns {boolean}
	 */
	isNotSeen() {
		return this.state === PageObject.STATE_NOT_SEEN
	}
	/**
	 * una pagina è completa se ha TUTTE le risposte piene e diverse da only_seen
	 * @returns {boolean}
	 */
	isCompleted() {
		return this.state === PageObject.STATE_COMPLETED
	}

	/**
	 * se il risultato di isAccessiblee' positivo restituisce se stessa, altrimenti
	 * riprova con la pagina successiva o precedente (in base a direction)
	 * @param direction {String} 'prev' or 'next'
	 * @returns {PageObject|*}
	 */
	meOr(direction) {
		if(!this.isAccessible()) {
			//verifica non passata, provo un'altra pagina
			const pageId = direction === 'next' ? getNextId(this.id) : getPrevId(this.id)
			const page = this.parent.pages.find(page => page.id === pageId)

			return page.meOr(direction)
		}

		return this
	}

	/**
	 * esegue un controllo su questa pagina in base alla condizione page.if,
	 */
	isAccessible() {
		if(this.if) {
			for(let questionId in this.if) {
				if(!this.if.hasOwnProperty(questionId)) {
					continue
				}
				/**
				 * @var questionValuesToCheck {Array}
				 * @var questionToCheck {QuestionObject}
				 */
				const questionValuesToCheck = this.if[questionId]
				const questionToCheck = this.mainStore.user.questions[questionId]
				//verifica che alla domanda e' stato risposto con uno dei valori di questionValuesToCheck
				if(
					questionToCheck &&
					!questionValuesToCheck.find(value => questionToCheck.hasThisAnswerValue(value, true))
				) {
					//fail
					return false
				}
			}
		}
		return true
	}

	async goToPrevPage() {
		let pageId
		if(this.isFirst) {
			return `${this.parent.id}_intro`
		}
		else {
			pageId = getPrevId(this.id)

			const page = this.parent.getPageById(pageId)
			if(!page) {
				return `${this.parent.id}_intro`
			}

			pageId = page.meOr('prev').id
		}
		await this.navigationStore.goToPage(pageId)
	}

	async goToNextPage() {
		let pageId

		if(this.isLast) {
			pageId = `${this.parent.id}_end`
		}
		else {
			pageId = getNextId(this.id)

			const page = this.parent.getPageById(pageId)
			if(!page) {
				pageId = `${this.parent.id}_intro`
			}
			else {
				pageId = page.meOr('next').id
			}
		}

		await this.navigationStore.goToPage(pageId)
	}

	/**
	 * Salva le eventuali domande zozze
	 * @returns {Promise<void>}
	 */
	async save() {//domande con nuova risposta da salvare
		const answersToSave = this.questions
			.filter(question => question.canBeSavedToDb())

		if(answersToSave.length) {
			try {
				await saveRemoteAnswers(
					answersToSave.map(question => question.answerDbFormat(this))
				)
			} catch(e) {
				console.error('Page.save catch', e);
			}

			//console.log('pageSaved', usePage().props?.user?.achievements.length)

			//set user.score, set user.achievements, hydrate user.questions
			this.mainStore.user.hydrate(usePage().props.user)
		}
	}

	mustConfirmAnyAnswers() {
		return !!this.questions.find(question => question.mustConfirmAnswer())
	}

	/**
	 * @param force {boolean} true per il debug
	 */
	setAsSeen(force = false) {
		this.questions.forEach(question => question.setAsSeen(force))
	}

	/**
	 * @param questionSupertype {?string} 'question'|'video'|'game', invece null usa tutte le risposte
	 * @returns {number}
	 */
	score(questionSupertype = null) {
		return (
			questionSupertype ? this.getQuestionsBySupertype(questionSupertype) : this.questions
		).reduce(
			(previousValue, question) => previousValue + (question.answerScore || 0),
			0
		)
	}

	getQuestionsBySupertype(questionSupertype) {
		return this.questions.filter(question => question.supertype === questionSupertype)
	}

	hasVideoQuestions() {
		return !!this.questions.find(question => question.type === QuestionObject.TYPE_VIDEO)
	}

	//debug start
	complete() {
		this.questions.forEach(question => question.complete())
	}
	notSeen() {
		this.questions.forEach(question => question.notSeen())
	}
	//debug end
}
