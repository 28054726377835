import moment from 'moment/moment.js';
import {PageObject} from '@/dataObjects/PageObject.js';
import {onMounted, onUpdated, reactive, ref, watch} from 'vue';
import {useNavigationStore} from '@/stores/navigation.js';
import {useMainStateStore} from '@/stores/mainState.js';
import $ from 'jquery'

export class ModuleObject {
	static STATE_UNACCESSIBLE = 'unaccessible'
	static STATE_ACCESSIBLE_EDITABLE = 'accessible_editable'
	static STATE_COMPLETED = 'completed'
	static STATE_ACCESSIBLE_UNEDITABLE = 'accessible_uneditable'

	constructor(moduleData, props = {}) {
		this.index = props.index
		this.isFirst = props.isFirst
		this.isLast = props.isLast

		this.id = `module${this.index}`
		this.navigationStore = useNavigationStore()
		this.mainStore = useMainStateStore()
		this.title = moduleData.title || `Modulo ${this.index}`
		this.date0 = moduleData.date0 + ' 00:00:00'
		this.date1 = moduleData.date1 + ' 23:59:59'

		this.intro = moduleData.intro
		this.intro.id = `module${this.index}_intro`
		if(!this.intro.title) {
			this.intro.title = `Intro modulo ${this.index}`
		}
		this.intro.goToNextPage = async () => {
			await this.navigationStore.goToPage(`${this.id}_page1`)
		}
		this.end = moduleData.end
		this.end.id = `module${this.index}_end`
		if(!this.end.title) {
			this.end.title = `end modulo ${this.index}`
		}

		this.state = ref(ModuleObject.STATE_UNACCESSIBLE)
		/**
		 * @type {PageObject[]}
		 */
		this.pages = moduleData.pages.map((page, index) => {
			return reactive(new PageObject(page, this, {
				index: index,
				isFirst: index === 0,
				isLast: index === moduleData.pages.length - 1,
			}))
		})

		const getState = () => {
			const notCompleted = this.pages.find(page => (page.isAccessible() && !page.isCompleted()))

			if(!notCompleted) {
				return ModuleObject.STATE_COMPLETED
			}

			/**
			 * un modulo è inaccessibile prima della data inizio
			 * un modulo è accessibile tra la data inizio e la data fine
			 * un modulo è accessibile dopo la data fine, se ha almeno una pagina visitata
			 * un modulo è modificabile solo tra la data inizio e la data fine
			 */
			const today = this.navigationStore.fakeNow ? moment(this.navigationStore.fakeNow) : moment()

			//return ModuleObject.STATE_COMPLETED
			if(today.isBefore(this.date0)) {
				return ModuleObject.STATE_UNACCESSIBLE
			}

			if(today.isAfter(this.date1)) {
				/**
				 * visto che una pagina senza domande ha sempre lo stato completato,
				 * non posso usarne lo stato per sapere se è stata visitata
				 */
				const pageSeen = this.pages.find(page => {
					if(!page.isNotSeen() && page.questions?.length) {
						return true
					}
					return false
				})

				if(!pageSeen) {
					return ModuleObject.STATE_UNACCESSIBLE
				}

				return ModuleObject.STATE_ACCESSIBLE_UNEDITABLE
			}

			return ModuleObject.STATE_ACCESSIBLE_EDITABLE
		}

		/**
		 * Watch si occupa di aggiornare state nel caso l'array pages cambi,
		 * ovvero nel caso cambi lo stato di una page.
		 *
		 * L'array pages non è un reattivo, ma ogni singola page si, quindi watch lo gestisce comunque bene.
		 * Non è vero, watch viene chiamato troppe volte! Dipende dalla martellata di QuestionObject.isAnswered
		 */
		watch(this.pages, () => {
			/**
			 * Il modulo è in stato STATE_ACCESSIBLE_UNEDITABLE, questo stato viene usato da QuestionObject.isAnswered
			 * per definire se è da bloccarne la modifica nel frontend, isAnswered viene usata da Page.getState per
			 * definire il proprio stato, il cambio di stato di Page fa scattare il watch di Module e si riparte da,
			 * capo fino alla fine dei tempi e la morte termica dell'universo per raggiunto equilibrio termodinamico.
			 *
			 * Se una martellata ha rischiato di distruggere il Creato, un'altra martellata riuscirà a salvarlo. Forse.
			 */
			if(this.id === 'module0' && this.state.value === ModuleObject.STATE_ACCESSIBLE_UNEDITABLE) {
				return
			}
			this.state.value = getState()
		})

		this.state.value = getState()
	}

	/**
	 * vengono considerate solo le pagine con domande
	 * una pagina è completa se ha TUTTE le risposte
	 * @returns {number}
	 */
	completionPerc() {
		const totalPages = this.pages.filter(page => {
            if (page.isAccessible()){
                return page.questions?.length
            } else {
                return 0
            }})
		const completedPages = totalPages.filter(page => {
            if (page.isAccessible()){
                return page.isCompleted()
            } else {
                return 0
            }})
		return 100 * completedPages.length / totalPages.length
	}

	/**
	 * @param questionSupertype {string}
	 * @returns {QuestionObject[]}
	 */
	getQuestionsBySupertype(questionSupertype) {
		const questions = []
		this.pages.forEach(page => {
			const pageSuperType = page.getQuestionsBySupertype(questionSupertype)
			if(pageSuperType && pageSuperType.length){
				questions.push(pageSuperType)
			}
		})
		return questions
	}

	getPageById(pageId) {
		const page = this.pages.find(page => page.id === pageId)
		if(!page) {
			console.warn('pageId not found', pageId)
			return null
		}
		return page
	}

	/**
	 * @param questionSupertype {?string} 'question' | 'video' | 'game'
	 * @returns {number}
	 */
	score(questionSupertype = null) {
		return this.pages.reduce(
			(previousValue, page) => previousValue + page.score(questionSupertype),
			0
		)
	}

	/**
	 * controlli di accessibilita'
	 * vanno fatti in onMounted perche l'utente puo arrivare in una pagina in modi
	 * diversi (url diretto, bug di navigazione, navigazione da cronologia)
	 *
	 * @param index {Number}
	 * @returns {PageObject}
	 */
	pageSetup(index) {
		const page = this.pages.find(page => page.index === index)

		onMounted(async () => {

			const safetyOffset = 70
			$('.scrollbar__scroller').height($('main').height() - safetyOffset)

			//modulo completato, tutte le pagine sono accessibili e non serve piu salvare risposte
			if(this.state === ModuleObject.STATE_COMPLETED) {
				return
			}

			//modulo bloccato, nessuna pagina e' accessibile
			if(this.state === ModuleObject.STATE_UNACCESSIBLE) {
				await this.navigationStore.goHome()
				return
			}
			//modulo disponibile, non sono accessibili le pagine successive all'ultima non completata
			else if(this.state === ModuleObject.STATE_ACCESSIBLE_EDITABLE) {
				// let nextPage = this.nextPageNotCompleted(index)
				const nextPageNotCompleted = this.pages.find(page => {
					return page.isAccessible() && !page.isCompleted()
				})
				if(!nextPageNotCompleted) {
					console.warn('no nextPageNotCompleted')
					return
				}

				//pagina non accessibile, redirect
				if(!page.isAccessible() || page.index > nextPageNotCompleted.index) {
					console.warn('force goTo nextPageNotCompleted', 'from', page.id, 'To', nextPageNotCompleted.id)
					await this.navigationStore.goToPage(nextPageNotCompleted.id)
					return
				}
			}

			//aggiorna le risposte non ancora viste come 'viste' e salva in db solo la prima volta
			if(page.isNotSeen()) {
				page.setAsSeen()
				await page.save()
			}
		})

		return page
	}

	introSetup() {
		onMounted(async() => {
			//la intro non e' accessibile solo in caso di modulo non accessibile
			if(this.state === ModuleObject.STATE_UNACCESSIBLE) {
				await this.navigationStore.goHome()
			}
		})
		return this.intro
	}

	endSetup() {
		onMounted(async() => {
			//si arriva alla end solo in caso di modulo completato, oppure accessibile non modificabile
			if(
				this.state !== ModuleObject.STATE_COMPLETED &&
				this.state !== ModuleObject.STATE_ACCESSIBLE_UNEDITABLE
			) {
				await this.navigationStore.goHome()
			}
		})
		return this.end
	}

	/**
	 * SCARTO
	 * un modulo è accessibile solo a partire dalla data inizio
	 * un modulo è accessibile anche dopo la data fine, ma solo se è ha almeno una pagina visitata
	 * @returns {boolean}
	 */
	isOpen() {
		try {
			const today = this.navigationStore.fakeNow ? moment(this.navigationStore.fakeNow) : moment()

			if(today.isBefore(this.date0)) {
				return false
			}

			if(today.isAfter(this.date1)) {
				/**
				 * visto che una pagina senza domande ha sempre lo stato completata,
				 * non posso usarne lo stato per sapere se è stata visitata
				 */
				const pageSeen = this.pages.find(page => {
					if(!page.isNotSeen() && page.questions?.length) {
						return true
					}
					return false
				})

				if(!pageSeen) {
					return false
				}
			}

			return true
		}
		catch(e) {
			console.warn(this.date0, this.date1, e)
			return false
		}
	}
	//debug start
	complete() {
		this.pages.forEach(page => page.complete())
	}
	notSeen() {
		this.pages.forEach(page => page.notSeen())
	}
	setAsSeen() {
		this.pages.forEach(page => page.setAsSeen())
	}
	//debug end
}
