<script setup>
import {useNavigationStore} from '@/stores/navigation.js';
import {useMainStateStore} from '@/stores/mainState.js';
import {compClass} from '@/libraries/utils.js';
import HamburgerIcon from '@/Pages/sharedComponents/icons/Hamburger.vue';
import XIcon from '@/Pages/sharedComponents/icons/X.vue';
import CircledStarIcon from '@/Pages/sharedComponents/icons/CircledStar.vue';
import PointLeftIcon from '@/Pages/sharedComponents/icons/PointLeft.vue';
import HomeIcon from '@/Pages/sharedComponents/icons/Home.vue';
import InsightsIcon from '@/Pages/sharedComponents/icons/Insights.vue';
import AboutIcon from '@/Pages/sharedComponents/icons/About.vue';
import LogoutIcon from '@/Pages/sharedComponents/icons/Logout.vue';
import {ref} from 'vue';
import Logo from '@/Pages/sharedComponents/images/Logo.vue';
import {useRoute, useRouter} from 'vue-router';

const currentRoute = useRoute()
// const vueRouter = useRouter()
// console.debug('currentRoute.matched', currentRoute, vueRouter.currentRoute)

const mainStore = useMainStateStore()
const navigationStore = useNavigationStore()

// il computed non reagisce a
// const score = computed(() => mainStore.user.score())
//$page.props e usePage().props sono undefined durante alcuni momenti della navigazione tramite cronologia

const panelIsVisible = ref(false)
const togglePanelVisibility = () => panelIsVisible.value = !panelIsVisible.value

const goHome = () => {
	if( !['about', 'insights', 'end'].includes(currentRoute.name) ) {
		navigationStore.goHome()
	}
	else {
		window.location.href = navigationStore.url('/')
	}
}
</script>

<template>
	<div :class="compClass()"
		class="flex justify-between items-center p-5 text-coral">
		<div class="basis-1/3">
			<div v-if="$route.name !== 'homeModules'"
				v-show="!panelIsVisible"
				class="pr-6 clickable"
				@click="goHome">
				<PointLeftIcon :color="'coral'" class="w-4 h-auto" />
			</div>
		</div>

		<div :class="panelIsVisible && 'opacity-0'"
			class="basis-1/3 flex justify-center items-center">
			<CircledStarIcon :color="'coral'" class="w-8 h-auto"/>
			<div class="pl-1 text-3xl open-sans font-bold">{{ mainStore.user.score }}</div>
		</div>

		<div class="basis-1/3 flex justify-end">
			<HamburgerIcon
				v-if="!panelIsVisible"
				:color="'coral'" class="w-10 h-auto clickable"
				@click="togglePanelVisibility"
			/>
			<XIcon
				v-if="panelIsVisible"
				:color="'coral'" class="w-7 h-auto mr-2 clickable"
				@click="togglePanelVisibility"
			/>
		</div>
	</div>

	<div class="absolute w-full min-h-screen py-4 pl-4 will-change-transform bg-gradient-to-b from-[#fffdf0] to-[#eaeded]"
		style="transition: transform .3s"
		:style="`transform:translateX(${panelIsVisible ? 0 : 100}%)`">

		<div class="h-full flex justify-center">
			<div class="h-full px-8 text-4xl text-blue">
				<Logo class="w-64 h-auto mb-14"/>

				<a class="flex items-end py-8" :href="navigationStore.url('/')">
					<HomeIcon :color="'coral'" class="w-14 h-auto"/>
					<div class="pl-6">Home</div>
				</a>
				<a class="flex items-end py-8" :href="navigationStore.url('/insights')">
					<InsightsIcon :color="'coral'" class="w-14 h-auto"/>
					<div class="pl-6">Approfondimenti</div>
				</a>
				<a class="flex items-end py-8" :href="navigationStore.url('/about')">
					<AboutIcon :color="'coral'" class="w-14 h-auto"/>
					<div class="pl-6">About</div>
				</a>
				<a class="flex items-end py-8" :href="navigationStore.url('/logout')">
					<LogoutIcon :color="'coral'" class="w-14 h-auto"/>
					<div class="pl-6 relative w-full">
						Logout
						<div class="absolute right-0 top-0 text-sm text-right" v-if="mainStore.environment === 'local'">
							{{mainStore.user.name}}<br>{{mainStore.user.is_treatment ? '' : 'not'}} treatment
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</template>
